import { useOrgLDFlag } from '@/auth/ld/hooks'

/**
 * useLD composable contains logics around LD flags usages.  Sometimes we do not use raw
 * LD flag value and there are logics built around LD flag that are shared across multiple
 * places.  This is a good place to store such logic and existing composables such as
 * instance and cluster is bloated and has dependency initialization order issue
 */
const isMaintenanceWindowEnabledFunction = () => {
  return useOrgLDFlag('enable-maintenance-window', false)
}

const isByocBaseOnCloudEnvironmentPossibleFunction = () => {
  return useOrgLDFlag('byoc-base-on-cloud-environment', false).value
}

export const useLD = () => {
  return {
    isMaintenanceWindowEnabledFunction,
    isByocBaseOnCloudEnvironmentPossibleFunction
  }
}
