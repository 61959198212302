import type { RouteLocationNormalized } from 'vue-router'
import { userMetadataSetKey } from '@/composables/localStorageKeys'

const lastViewedOrgInsKey = 'LAST_VIEWED_ORG_INS'
const lastViewedOrgInsSeparator = '|'
const sugerEntitlementIdKey = 'sugerEntitlementId'
const sugerPartnerKey = 'sugerPartner'
const awsMarketplaceTokenKey = 'awsMarketplaceToken'
const topicsPageVisitedKey = 'topicsPageVisited'
const clientsPageVisitedKey = 'clientsPageVisited'
const hideSetupGuideKey = 'hideSetupGuide'
const tenantNamespacePageVisitedKey = 'tenantNamespacePageVisited'

export const getLastViewedOrgIns = () => {
  return (localStorage.getItem(lastViewedOrgInsKey) ?? '').split(lastViewedOrgInsSeparator)
}

export const setLastViewedOrgIns = (to: RouteLocationNormalized) => {
  if (!to.query.org) {
    // org is not set, so do not delete the cache
    return
  }

  const org = getLastViewedOrgIns()[0]
  if (to.query.org === org && !to.query.instance) {
    // org is set but instance is not passed in, do not delete last visited instance
    return
  }

  localStorage.setItem(
    lastViewedOrgInsKey,
    `${to.query.org}${lastViewedOrgInsSeparator}${to.query.instance ?? ''}`
  )
}

export const clearLastViewedOrgIns = () => {
  localStorage.removeItem(lastViewedOrgInsKey)
}

export const getSugerEntitlementId = () => {
  return localStorage.getItem(sugerEntitlementIdKey)
}

export const setSugerEntitlementId = (sugerEntitlementId?: string) => {
  if (!sugerEntitlementId) {
    return
  }
  return localStorage.setItem(sugerEntitlementIdKey, sugerEntitlementId)
}

export const getSugerPartner = () => {
  return localStorage.getItem(sugerPartnerKey)
}

export const setSugerPartner = (sugerPartner?: string) => {
  if (!sugerPartner) {
    return
  }
  return localStorage.setItem(sugerPartnerKey, sugerPartner)
}

export const getAwsMarketplaceToken = () => {
  return localStorage.getItem(awsMarketplaceTokenKey)
}

export const setTopicsPageVisited = () => {
  return localStorage.setItem(topicsPageVisitedKey, 'true')
}

export const getTopicsPageVisited = () => {
  return localStorage.getItem(topicsPageVisitedKey) === 'true'
}

export const setClientsPageVisited = () => {
  return localStorage.setItem(clientsPageVisitedKey, 'true')
}

export const getClientsPageVisited = () => {
  return localStorage.getItem(clientsPageVisitedKey) === 'true'
}

export const setHideSetupGuide = (value: boolean) => {
  return localStorage.setItem(hideSetupGuideKey, value ? 'true' : 'false')
}

export const getHideSetupGuide = () => {
  return localStorage.getItem(hideSetupGuideKey) === 'true'
}

export const setTenantNamespacePageVisited = () => {
  return localStorage.setItem(tenantNamespacePageVisitedKey, 'true')
}

export const getTenantNamespacePageVisited = () => {
  return localStorage.getItem(tenantNamespacePageVisitedKey) === 'true'
}

export const clearCachedOrgMetadata = () => {
  localStorage.removeItem(sugerEntitlementIdKey)
  localStorage.removeItem(sugerPartnerKey)
  localStorage.removeItem(awsMarketplaceTokenKey)
}

export const clearUserMetadataSetKey = () => {
  localStorage.removeItem(userMetadataSetKey)
}
