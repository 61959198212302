/*
 * Pulled from https://github.com/launchdarkly/vue-client-sdk/blob/6cd132d69a0ff68e41c5b73ae481ab93fb13e3c1/src/hooks.ts
 *
 */
import { inject, type InjectionKey } from 'vue'
import {
  type LDPluginOptions,
  ORG_LD_CLIENT,
  ORG_LD_CONTEXT,
  ORG_LD_FLAG,
  ORG_LD_INIT,
  ORG_LD_READY
} from '@/auth/ld/ld'

const safeInject = <T>(key: InjectionKey<T>, label: string): T => {
  const target = inject(key)
  if (target === undefined) {
    throw new Error(`Injection of ${label} failed. LaunchDarkly plugin may not have been loaded.`)
  }
  return target
}

/**
 * Indicates if the LaunchDarkly client has finished initializing. Uses Vue's inject API, and will only
 * work if run inside a Vue setup hook or `<script setup>`.
 *
 * @return Readonly boolean reference indicating if the LaunchDarkly client has finished initializing.
 */
export function useOrgLDReady() {
  return safeInject(ORG_LD_READY, 'ORG_LD_READY')
}

/**
 * Provides the LaunchDarkly JavaScript client,
 * {@link https://launchdarkly.github.io/js-client-sdk/interfaces/_launchdarkly_js_client_sdk_.ldclient.html}.
 * Uses Vue's inject API, and will only work if run inside a Vue setup hook or `<script setup>`.
 */
export function useOrgLDClient() {
  return safeInject(ORG_LD_CLIENT, 'ORG_LD_CLIENT')
}

/**
 * Evaluates a single feature flag. Automatically subscribes to streamed updates
 * unless the `streaming` option was set to false.
 * Uses Vue's inject API, so will only work if run inside a Vue setup hook or `<script setup>`.
 *
 * @typeParam T - Type of the flag's value. Can be inferred if a default value is provided.
 * @param flagKey Key of the feature flag to be evaluated.
 * @param defaultValue Default value to be used while flag value loads, or if flag cannot be found.
 *
 * @return Readonly ref to the flag's value.
 */
export function useOrgLDFlag<T>(flagKey: string, defaultValue?: T) {
  return safeInject(ORG_LD_FLAG, 'ORG_LD_FLAG')(flagKey, defaultValue)
}

/**
 * Initializes the LaunchDarkly client.
 * Uses Vue's inject API, and will only work if run inside a Vue setup hook or `<script setup>`.
 */
export function orgLdInit(initOptions: LDPluginOptions) {
  return safeInject(ORG_LD_INIT, 'ORG_LD_INIT')(initOptions)
}

export function useOrgLDContext() {
  const ldUser = inject(ORG_LD_CONTEXT)

  if (!ldUser) {
    throw new Error('Cannot inject LDUser instance because it was not provided')
  }

  return ldUser
}
