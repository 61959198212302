import * as kafkaApi from '@/api-clients/kafkaconnect-client-typescript/index'

import { getBasePath } from '@/composables/pulsarAdmin'
import getAxiosInstance from '../utils/axios'
import { auth } from '@/auth'
import type { Cluster } from '@/composables/useCluster'
import { Buffer } from 'buffer'

export class ConnectorClientFactory {
  static createConnectorClient(organization: string, cluster: Cluster, audience: string) {
    return new KafkaConnectorClient(organization, cluster, audience)
  }
}

// TODO maybe just make this a static class
//      with a method for createClient since
//      the initializer and public var is kind of
//      useless
export class KafkaConnectorClient {
  public client

  constructor(organization: string, cluster: Cluster, audience: string) {
    const axios = getAxiosInstance()
    const clusterUid = cluster.metadata.uid
    if (!clusterUid) {
      throw new Error('Cluster uid is required to create Kafka connector client')
    }

    axios.interceptors.request.use(
      async config => {
        const { getAccessTokenSilently } = auth
        const token = await getAccessTokenSilently({
          authorizationParams: {
            audience: audience,
            scope: 'admin'
          }
        })

        // The token for kafkaconnect to work
        // is done with basic access with our token
        // as the password and the tenant/ns as the username and base64 encoded
        const { tenant, namespace } = usePulsarState()
        const str = `${tenant.value || 'public'}/${namespace.value || 'default'}:${token}`
        const b64str = Buffer.from(str, 'binary').toString('base64')
        config.headers['Authorization'] = `Basic ${b64str}`
        return config
      },
      async e => {
        return Promise.reject(e)
      }
    )

    this.client = kafkaApi.DefaultApiFactory(
      undefined,
      `${getBasePath(organization, clusterUid)}/admin/kafkaconnect`,
      axios
    )
  }

  public createClient(organization: string, cluster: Cluster, token: string) {
    const axios = getAxiosInstance()
    const clusterUid = cluster.metadata.uid
    if (!clusterUid) {
      throw new Error('Cluster uid is required to create Kafka connector client')
    }

    axios.interceptors.request.use(
      async config => {
        // The token for kafkaconnect to work
        // is done with basic access with our token
        // as the password and the tenant/ns as the username and base64 encoded
        const { tenant, namespace } = usePulsarState()
        const str = `${tenant.value || 'public'}/${namespace.value || 'default'}:${token}`
        const b64str = Buffer.from(str, 'binary').toString('base64')
        config.headers['Authorization'] = `Basic ${b64str}`
        console.error('header', config.headers['Authorization'])
        return config
      },
      async e => {
        return Promise.reject(e)
      }
    )

    return kafkaApi.DefaultApiFactory(
      undefined,
      `${getBasePath(organization, clusterUid)}/admin/kafkaconnect`,
      axios
    )
  }
}
