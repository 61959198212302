import authConfig from 'Config/auth_config.json'
import getAxiosInstance from '@/utils/axios'
import { auth } from '@/auth'
import { userMetadataSetKey } from '@/composables/localStorageKeys'
export const userMetadataAnnotation = 'https://streamnative.io/user-metadata'
export const userMetadataKey = 'info'

export interface UserMetadataInfo {
  fullName: string
  isNewsLetterSubscribed?: boolean
  isInfoEmailSubscribed?: boolean
  role?: string
  pulsarKnowledge?: string
}

// user metadata token shouldn't change within our app.  It willy only change when the logged in
// auth0 changes, which will be a page reinstantiation so lazily load the token
let userMetadataToken = ''
const axiosInstance = getAxiosInstance()
axiosInstance.interceptors.request.use(
  async config => {
    // set auth
    config.headers['Authorization'] = `Bearer ${await getUserMetadataToken()}`

    return config
  },
  async e => {
    return Promise.reject(e)
  }
)

const getUserMetadataToken = async (): Promise<string> => {
  if (userMetadataToken === '') {
    userMetadataToken = await auth.getAccessTokenSilently({
      authorizationParams: {
        audience: authConfig.userMetadataAudience,
        scope: 'read:current_user create:current_user_metadata update:current_user_metadata'
      }
    })
  }
  return userMetadataToken
}

/**
 * values is stored as user_metadata within auth0 and this values is passed in as an user object
 * after login.
 *
 * Auth0 userInfo will return data at the point of login, which maybe stale.
 * https://community.auth0.com/t/auth0-returns-a-stale-userinfo/13030/6
 */
export const getUserMetadata = (): UserMetadataInfo | undefined => {
  const user = auth.user.value
  if (user && user[userMetadataAnnotation] && user[userMetadataAnnotation][userMetadataKey]) {
    return user[userMetadataAnnotation][userMetadataKey]
  }

  // this local storage check exists because `user[userMetadataAnnotation][userMetadataKey]` is still false
  // if user logs in and set the userMetdata.  Until user relogs in, user object doesn't get refreshed.
  const localStoragteCache = localStorage.getItem(userMetadataSetKey)
  if (localStoragteCache) {
    try {
      return JSON.parse(localStoragteCache)
    } catch {
      // do nothing
    }
  }

  return undefined
}

export const updateUserMetadata = async (userMetadataInfo: UserMetadataInfo) => {
  const user = auth.user.value || {}
  const auth0UserId = user['sub']

  if (!auth0UserId) {
    throw Error('Auth0 user Id is missing.')
  }

  const currentUserMetadata = user[userMetadataAnnotation]
    ? JSON.parse(JSON.stringify(user[userMetadataAnnotation]))
    : {}
  currentUserMetadata[userMetadataKey] = userMetadataInfo

  await axiosInstance.patch(`https://${authConfig.domain}/api/v2/users/${auth0UserId}`, {
    user_metadata: currentUserMetadata
  })
  localStorage.setItem(userMetadataSetKey, JSON.stringify(userMetadataInfo))
}

/**
 * Because of stale data issue at `getUserMetadata()`, we are also checking localStorage flag.
 */
export const isUserHasMetadata = async () => {
  return !!(await getUserMetadata())
}

export const useUserMetadata = () => {
  return {
    getUserMetadata,
    updateUserMetadata,
    isUserHasMetadata
  }
}
