import { fetchTenants, fetchAdministeredTenants } from '@/api/tenants'
import { fetchNamespacePolicies, fetchNamespaces } from '@/api/namespaces'
import { useError } from '@/composables/provider'
import type { Policies } from '@streamnative/pulsar-admin-client-typescript'
import type { Cluster } from './useCluster'
import type { PulsarState } from './usePulsarState'
import {
  TenantWrapper,
  adminTenant,
  PulsarCluster,
  PulsarInstance,
  use,
  useRbac,
  PulsarClusterWrapper
} from './useRbac'

const errorGettingTenants = ref<{ message: string; e: unknown } | undefined>(undefined)
const errorGettingNamespaces = ref<{ message: string; e: unknown } | undefined>(undefined)

// TODO this file should be renamed to useTenants.ts and we should split the
//      tenant and namespace logic into separate files

let lastClusUid: string | undefined = undefined

interface TenantNamespaces {
  [tenant: string]: { [namespace: string]: Policies }
}
const tenantNamespaces = ref<TenantNamespaces>({})
const tenantNames = computed<string[]>(() => Object.keys(tenantNamespaces.value).sort())
const setTenants = async (params?: { organization?: string; clusterUid?: string }) => {
  const { mustOrganization, mustClusterUid } = usePulsarState()
  try {
    const result: TenantNamespaces = {}
    const tenantsList = (
      await fetchTenants({
        organization: params?.organization || mustOrganization(),
        clusterUid: params?.clusterUid || mustClusterUid()
      })
    ).data
    errorGettingTenants.value = undefined
    tenantsList.forEach(tenant => {
      result[tenant] = {}
    })
    tenantNamespaces.value = result
  } catch (e) {
    useError(e)
    if (Object.keys(tenantNamespaces.value).length > 0) {
      tenantNamespaces.value = {}
    }
    errorGettingTenants.value = { e, message: getErrorMessage(e) }
  }
}

// TODO we need to set additional metadata on these to indicate you cannot
//      actually 'admin' the tenant, you can only admin under the tenant e.g
//      the namespaces, etc.
const setAdministeredTenants = async (
  organization: string,
  clusterUid: string,
  instance: string
) => {
  try {
    const result: TenantNamespaces = {}
    const tenantsList = await fetchAdministeredTenants(organization, clusterUid, instance)
    if (tenantsList) {
      const { data: tenants } = tenantsList
      tenants.forEach(tenant => {
        result[tenant] = {}
      })
      tenantNamespaces.value = result
    }
    errorGettingTenants.value = undefined
  } catch (e) {
    useError(e)
    if (Object.keys(tenantNamespaces.value).length > 0) {
      tenantNamespaces.value = {}
    }
    errorGettingTenants.value = { e, message: getErrorMessage(e) }
  }
}

const namespacesLoading = ref(false)
const setTenantNamespaces = async (tenant?: string) => {
  namespacesLoading.value = true
  try {
    tenant = tenant || usePulsarState().mustTenant()
    if (Object.keys(tenantNamespaces.value).length === 0) {
      tenantNamespaces.value[tenant] = {}
    }
    const result: { [namespace: string]: Policies } = {}
    const namespaceList = (await fetchNamespaces({ tenant })).data
    namespaceList.forEach(namespace => {
      result[namespace] = {}
    })
    tenantNamespaces.value[tenant] = result
    errorGettingNamespaces.value = undefined
  } catch (e) {
    console.error(e)
    useError(e)
    if (tenant && Object.keys(tenantNamespaces.value[tenant]).length > 0) {
      tenantNamespaces.value[tenant] = {}
    }
    errorGettingNamespaces.value = { e, message: getErrorMessage(e) }
  } finally {
    namespacesLoading.value = false
  }
}

const setNamespacePolicies = async (params: { tenant: string; namespace: string }) => {
  const { mustOrganization, mustClusterUid } = usePulsarState()

  const policies = (
    await fetchNamespacePolicies({
      organization: mustOrganization(),
      clusterUid: mustClusterUid(),
      ...params
    })
  ).data

  if (!(params.tenant in tenantNamespaces.value)) {
    tenantNamespaces.value[params.tenant] = {}
  }
  tenantNamespaces.value[params.tenant][params.namespace] = policies
}

export const init = (
  initialState: PulsarState,
  can?: ((action: string, type: any, conditions?: any) => boolean) | true
) => {
  const { organization, tenant, instance } = usePulsarState()
  const { activeCluster, isActiveClusterAvailable } = useCluster()
  const { abilityUpdating } = useRbac()
  const valueChanged = async ([org, cluster, ten, ab]: [
    string | undefined,
    Cluster | undefined,
    string | undefined,
    boolean | undefined
  ]) => {
    const isClusterAvailable = isActiveClusterAvailable.value ?? false
    const clusterUid = cluster?.metadata?.uid ?? undefined

    if (ab) {
      return
    }

    const { enabled } = useRbac()
    if (!org || !isClusterAvailable || !clusterUid) {
      tenantNamespaces.value = {}
      lastClusUid = undefined
      return
    }

    if (clusterUid !== lastClusUid || !ab) {
      lastClusUid = clusterUid
      // if the cluster changes we need to set what tenants are administered
      let specificCan = false
      if (activeCluster.value) {
        const clusterWrapper = new PulsarClusterWrapper(activeCluster.value)
        if (typeof can === 'function') {
          specificCan = can(adminTenant, clusterWrapper)
        }
      }
      if (
        can &&
        (can === true || can(adminTenant, PulsarCluster) || specificCan) &&
        clusterUid &&
        instance.value &&
        // need to check if rbac is enabled before calling any plugin api's
        // you can have the plugin installed _and_ configured but not be enabled
        enabled.value
      ) {
        // setAdministeredTenants has a race with setTenants, both operate on the
        // same ref and thus can have odd behaviors if you have 'use' permissions
        // AND adminTenant permissions
        await setAdministeredTenants(org, clusterUid, instance.value)
      }

      // seeking tenants out requires super-admin rights on the cluster
      // so you have to be able to admin Tenant. it is probably better
      // even here to do admin, PulsarInstance
      // TODO this probably should be admin, PulsarInstance
      if (can && (can === true || can(use, PulsarInstance) || can(use, PulsarCluster))) {
        // TODO is there a race here with setAdministeredTenants?
        await setTenants({ organization: org, clusterUid })
      }
    }

    if (ten) {
      // can you administer this particular tenant? if so, request namespaces
      const tenantWrapper = new TenantWrapper(ten, instance.value, cluster?.metadata?.name)
      if (
        can &&
        (can === true ||
          can(use, PulsarInstance) ||
          can(use, PulsarCluster) ||
          can(adminTenant, tenantWrapper))
      ) {
        await setTenantNamespaces(ten)
      }
    }
    lastClusUid = clusterUid
  }

  // TODO why would you only watch clusters but not instances?
  watch([organization, activeCluster, tenant, abilityUpdating], valueChanged)
  return valueChanged([
    initialState.organization,
    activeCluster.value,
    initialState.tenant,
    abilityUpdating.value
  ])
}

const systemTenantNamespaces = [
  'sn/system',
  'pulsar/system',
  'public/__kafka',
  'public/__kafka_schemaregistry'
]

export const useTenantNamespace = () => {
  return {
    setAdministeredTenants,
    setTenants,
    namespacesLoading,
    setTenantNamespaces,
    tenantNames,
    tenantNamespaces,
    removeTenant: (name: string) => {
      delete tenantNamespaces.value[name]
    },
    addTenant: (name: string) => {
      tenantNamespaces.value[name] = {}
    },
    removeNamespace: (tenant: string, name: string) => {
      delete tenantNamespaces.value[tenant][name]
    },
    addNamespace: (tenant: string, name: string) => {
      tenantNamespaces.value[tenant][name] = {}
    },
    setNamespacePolicies,
    systemTenantNamespaces,
    isSystemTenantNamespace: (tenant: string, namespace: string) =>
      systemTenantNamespaces.includes(tenant + '/' + namespace),
    init,
    errorGettingTenants,
    errorGettingNamespaces
  }
}
