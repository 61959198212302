import type { Location, Pool } from '@/api/organizations'
import { getLocationsAndPools as getLocationsAndPoolsApi } from '@/api/organizations'
import type { PulsarState } from './usePulsarState'
import { view, PoolOption, useRbac } from '@/composables/useRbac'

let lastOrg: string | undefined = undefined
const locations = ref<Location[]>([])
const managedPools = ref<Location[]>([])
const hostedPools = ref<Location[]>([])
const pools = ref<Pool[]>([])

const locationsAndPoolsUpdated = (locationsAndPools: { locations: Location[]; pools: Pool[] }) => {
  locations.value = locationsAndPools.locations
  pools.value = locationsAndPools.pools
  managedPools.value = locations.value.filter(loc => loc.pool.deploymentType === 'managed')
  hostedPools.value = locations.value.filter(loc => loc.pool.deploymentType === 'hosted')
}

export const init = (
  initialState: PulsarState,
  can?: ((action: string, type: any, conditions?: any) => boolean) | true
) => {
  const { organization } = usePulsarState()
  const { abilityUpdating } = useRbac()

  const valueChanged = async ([org, ab]: [string | undefined, boolean | undefined]) => {
    if (!org) {
      locationsAndPoolsUpdated({ locations: [], pools: [] })
      lastOrg = undefined
      return
    }
    if (ab) {
      return
    }

    if (org !== lastOrg || !ab) {
      if (can && (can === true || can(view, PoolOption))) {
        locationsAndPoolsUpdated(await getLocationsAndPoolsApi(org))
      }
    }
    lastOrg = org
  }

  watch([organization, abilityUpdating], valueChanged)
  return valueChanged([initialState.organization, abilityUpdating.value])
}

export const getLocationsAndPools = async (
  can?: ((action: string, type: any, conditions?: any) => boolean) | true
) => {
  const { mustOrganization } = usePulsarState()
  if (can && (can === true || can(view, PoolOption))) {
    locationsAndPoolsUpdated(await getLocationsAndPoolsApi(mustOrganization()))
  }
}

export const findPool = (poolRef: { name: string; namespace: string } | undefined) => {
  if (!poolRef) {
    return undefined
  }
  return pools.value.find(
    p => p.poolRef.name === poolRef.name && p.poolRef.namespace === poolRef.namespace
  )
}

export const usePools = () => {
  return {
    locations,
    pools,
    managedPools,
    hostedPools,
    init,
    findPool,
    getLocationsAndPools
  }
}
