/* tslint:disable */
/* eslint-disable */
/**
 * Kafka Connect With Pulsar API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @interface ConfigFieldDefinition
 */
export interface ConfigFieldDefinition {
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof ConfigFieldDefinition
     */
    'attributes'?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof ConfigFieldDefinition
     */
    'fieldName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ConfigFieldDefinition
     */
    'typeName'?: string;
}
/**
 * 
 * @export
 * @interface ConfigInfo
 */
export interface ConfigInfo {
    /**
     * 
     * @type {ConfigKeyInfo}
     * @memberof ConfigInfo
     */
    'definition'?: ConfigKeyInfo;
    /**
     * 
     * @type {ConfigValueInfo}
     * @memberof ConfigInfo
     */
    'value'?: ConfigValueInfo;
}
/**
 * 
 * @export
 * @interface ConfigInfos
 */
export interface ConfigInfos {
    /**
     * 
     * @type {Array<ConfigInfo>}
     * @memberof ConfigInfos
     */
    'configs'?: Array<ConfigInfo>;
    /**
     * 
     * @type {number}
     * @memberof ConfigInfos
     */
    'error_count'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof ConfigInfos
     */
    'groups'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ConfigInfos
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface ConfigKeyInfo
 */
export interface ConfigKeyInfo {
    /**
     * 
     * @type {string}
     * @memberof ConfigKeyInfo
     */
    'default_value'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ConfigKeyInfo
     */
    'dependents'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ConfigKeyInfo
     */
    'display_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ConfigKeyInfo
     */
    'documentation'?: string;
    /**
     * 
     * @type {string}
     * @memberof ConfigKeyInfo
     */
    'group'?: string;
    /**
     * 
     * @type {string}
     * @memberof ConfigKeyInfo
     */
    'importance'?: string;
    /**
     * 
     * @type {string}
     * @memberof ConfigKeyInfo
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof ConfigKeyInfo
     */
    'order'?: number;
    /**
     * 
     * @type {number}
     * @memberof ConfigKeyInfo
     */
    'order_in_group'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ConfigKeyInfo
     */
    'required'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ConfigKeyInfo
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof ConfigKeyInfo
     */
    'width'?: string;
}
/**
 * 
 * @export
 * @interface ConfigValueInfo
 */
export interface ConfigValueInfo {
    /**
     * 
     * @type {Array<string>}
     * @memberof ConfigValueInfo
     */
    'errors'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ConfigValueInfo
     */
    'name'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ConfigValueInfo
     */
    'recommended_values'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ConfigValueInfo
     */
    'value'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ConfigValueInfo
     */
    'visible'?: boolean;
}
/**
 * 
 * @export
 * @interface ConnectorInfo
 */
export interface ConnectorInfo {
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof ConnectorInfo
     */
    'config'?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof ConnectorInfo
     */
    'name'?: string;
    /**
     * 
     * @type {Array<ConnectorTaskId>}
     * @memberof ConnectorInfo
     */
    'tasks'?: Array<ConnectorTaskId>;
    /**
     * 
     * @type {string}
     * @memberof ConnectorInfo
     */
    'type'?: ConnectorInfoTypeEnum;
}

export const ConnectorInfoTypeEnum = {
    Source: 'source',
    Sink: 'sink',
    Unknown: 'unknown'
} as const;

export type ConnectorInfoTypeEnum = typeof ConnectorInfoTypeEnum[keyof typeof ConnectorInfoTypeEnum];

/**
 * 
 * @export
 * @interface ConnectorOffset
 */
export interface ConnectorOffset {
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof ConnectorOffset
     */
    'offset'?: { [key: string]: object; };
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof ConnectorOffset
     */
    'partition'?: { [key: string]: object; };
}
/**
 * 
 * @export
 * @interface ConnectorOffsets
 */
export interface ConnectorOffsets {
    /**
     * 
     * @type {Array<ConnectorOffset>}
     * @memberof ConnectorOffsets
     */
    'offsets'?: Array<ConnectorOffset>;
}
/**
 * 
 * @export
 * @interface ConnectorState
 */
export interface ConnectorState {
    /**
     * 
     * @type {string}
     * @memberof ConnectorState
     */
    'msg'?: string;
    /**
     * 
     * @type {string}
     * @memberof ConnectorState
     */
    'state'?: string;
    /**
     * 
     * @type {string}
     * @memberof ConnectorState
     */
    'trace'?: string;
    /**
     * 
     * @type {string}
     * @memberof ConnectorState
     */
    'worker_id'?: string;
}
/**
 * 
 * @export
 * @interface ConnectorStateInfo
 */
export interface ConnectorStateInfo {
    /**
     * 
     * @type {ConnectorState}
     * @memberof ConnectorStateInfo
     */
    'connector'?: ConnectorState;
    /**
     * 
     * @type {string}
     * @memberof ConnectorStateInfo
     */
    'name'?: string;
    /**
     * 
     * @type {Array<TaskState>}
     * @memberof ConnectorStateInfo
     */
    'tasks'?: Array<TaskState>;
    /**
     * 
     * @type {string}
     * @memberof ConnectorStateInfo
     */
    'type'?: ConnectorStateInfoTypeEnum;
}

export const ConnectorStateInfoTypeEnum = {
    Source: 'source',
    Sink: 'sink',
    Unknown: 'unknown'
} as const;

export type ConnectorStateInfoTypeEnum = typeof ConnectorStateInfoTypeEnum[keyof typeof ConnectorStateInfoTypeEnum];

/**
 * 
 * @export
 * @interface ConnectorTaskId
 */
export interface ConnectorTaskId {
    /**
     * 
     * @type {string}
     * @memberof ConnectorTaskId
     */
    'connector'?: string;
    /**
     * 
     * @type {number}
     * @memberof ConnectorTaskId
     */
    'task'?: number;
}
/**
 * 
 * @export
 * @interface CreateConnectorRequest
 */
export interface CreateConnectorRequest {
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof CreateConnectorRequest
     */
    'config'?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof CreateConnectorRequest
     */
    'initialState'?: CreateConnectorRequestInitialStateEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateConnectorRequest
     */
    'initial_state'?: CreateConnectorRequestInitialStateEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateConnectorRequest
     */
    'name'?: string;
}

export const CreateConnectorRequestInitialStateEnum = {
    Running: 'RUNNING',
    Paused: 'PAUSED',
    Stopped: 'STOPPED'
} as const;

export type CreateConnectorRequestInitialStateEnum = typeof CreateConnectorRequestInitialStateEnum[keyof typeof CreateConnectorRequestInitialStateEnum];

/**
 * 
 * @export
 * @interface FunctionMeshConnectorDefinition
 */
export interface FunctionMeshConnectorDefinition {
    /**
     * 
     * @type {string}
     * @memberof FunctionMeshConnectorDefinition
     */
    'defaultSchemaType'?: string;
    /**
     * 
     * @type {string}
     * @memberof FunctionMeshConnectorDefinition
     */
    'defaultSerdeClassName'?: string;
    /**
     * 
     * @type {string}
     * @memberof FunctionMeshConnectorDefinition
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof FunctionMeshConnectorDefinition
     */
    'iconLink'?: string;
    /**
     * 
     * @type {string}
     * @memberof FunctionMeshConnectorDefinition
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FunctionMeshConnectorDefinition
     */
    'imageRegistry'?: string;
    /**
     * 
     * @type {string}
     * @memberof FunctionMeshConnectorDefinition
     */
    'imageRepository'?: string;
    /**
     * 
     * @type {string}
     * @memberof FunctionMeshConnectorDefinition
     */
    'imageTag'?: string;
    /**
     * 
     * @type {string}
     * @memberof FunctionMeshConnectorDefinition
     */
    'jar'?: string;
    /**
     * 
     * @type {string}
     * @memberof FunctionMeshConnectorDefinition
     */
    'jarFullName'?: string;
    /**
     * 
     * @type {string}
     * @memberof FunctionMeshConnectorDefinition
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof FunctionMeshConnectorDefinition
     */
    'sinkClass'?: string;
    /**
     * 
     * @type {string}
     * @memberof FunctionMeshConnectorDefinition
     */
    'sinkConfigClass'?: string;
    /**
     * 
     * @type {Array<ConfigFieldDefinition>}
     * @memberof FunctionMeshConnectorDefinition
     */
    'sinkConfigFieldDefinitions'?: Array<ConfigFieldDefinition>;
    /**
     * 
     * @type {string}
     * @memberof FunctionMeshConnectorDefinition
     */
    'sinkDocLink'?: string;
    /**
     * 
     * @type {string}
     * @memberof FunctionMeshConnectorDefinition
     */
    'sinkTypeClassName'?: string;
    /**
     * 
     * @type {string}
     * @memberof FunctionMeshConnectorDefinition
     */
    'sourceClass'?: string;
    /**
     * 
     * @type {string}
     * @memberof FunctionMeshConnectorDefinition
     */
    'sourceConfigClass'?: string;
    /**
     * 
     * @type {Array<ConfigFieldDefinition>}
     * @memberof FunctionMeshConnectorDefinition
     */
    'sourceConfigFieldDefinitions'?: Array<ConfigFieldDefinition>;
    /**
     * 
     * @type {string}
     * @memberof FunctionMeshConnectorDefinition
     */
    'sourceDocLink'?: string;
    /**
     * 
     * @type {string}
     * @memberof FunctionMeshConnectorDefinition
     */
    'sourceTypeClassName'?: string;
    /**
     * 
     * @type {string}
     * @memberof FunctionMeshConnectorDefinition
     */
    'typeClassName'?: string;
    /**
     * 
     * @type {string}
     * @memberof FunctionMeshConnectorDefinition
     */
    'version'?: string;
}
/**
 * 
 * @export
 * @interface PluginInfo
 */
export interface PluginInfo {
    /**
     * 
     * @type {string}
     * @memberof PluginInfo
     */
    'class'?: string;
    /**
     * 
     * @type {string}
     * @memberof PluginInfo
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof PluginInfo
     */
    'version'?: string;
}
/**
 * 
 * @export
 * @interface ServerInfo
 */
export interface ServerInfo {
    /**
     * 
     * @type {string}
     * @memberof ServerInfo
     */
    'commit'?: string;
    /**
     * 
     * @type {string}
     * @memberof ServerInfo
     */
    'kafka_cluster_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ServerInfo
     */
    'version'?: string;
}
/**
 * 
 * @export
 * @interface TaskInfo
 */
export interface TaskInfo {
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof TaskInfo
     */
    'config'?: { [key: string]: string; };
    /**
     * 
     * @type {ConnectorTaskId}
     * @memberof TaskInfo
     */
    'id'?: ConnectorTaskId;
}
/**
 * 
 * @export
 * @interface TaskState
 */
export interface TaskState {
    /**
     * 
     * @type {number}
     * @memberof TaskState
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TaskState
     */
    'msg'?: string;
    /**
     * 
     * @type {string}
     * @memberof TaskState
     */
    'state'?: string;
    /**
     * 
     * @type {string}
     * @memberof TaskState
     */
    'trace'?: string;
    /**
     * 
     * @type {string}
     * @memberof TaskState
     */
    'worker_id'?: string;
}

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} connector 
         * @param {boolean} [forward] 
         * @param {ConnectorOffsets} [connectorOffsets] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        alterConnectorOffsets: async (connector: string, forward?: boolean, connectorOffsets?: ConnectorOffsets, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'connector' is not null or undefined
            assertParamExists('alterConnectorOffsets', 'connector', connector)
            const localVarPath = `/connectors/{connector}/offsets`
                .replace(`{${"connector"}}`, encodeURIComponent(String(connector)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (forward !== undefined) {
                localVarQueryParameter['forward'] = forward;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(connectorOffsets, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {boolean} [forward] 
         * @param {CreateConnectorRequest} [createConnectorRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createConnector: async (forward?: boolean, createConnectorRequest?: CreateConnectorRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/connectors`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (forward !== undefined) {
                localVarQueryParameter['forward'] = forward;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createConnectorRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} connector 
         * @param {boolean} [forward] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        destroyConnector: async (connector: string, forward?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'connector' is not null or undefined
            assertParamExists('destroyConnector', 'connector', connector)
            const localVarPath = `/connectors/{connector}`
                .replace(`{${"connector"}}`, encodeURIComponent(String(connector)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (forward !== undefined) {
                localVarQueryParameter['forward'] = forward;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} connector 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConnector: async (connector: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'connector' is not null or undefined
            assertParamExists('getConnector', 'connector', connector)
            const localVarPath = `/connectors/{connector}`
                .replace(`{${"connector"}}`, encodeURIComponent(String(connector)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} connector 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConnectorActiveTopics: async (connector: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'connector' is not null or undefined
            assertParamExists('getConnectorActiveTopics', 'connector', connector)
            const localVarPath = `/connectors/{connector}/topics`
                .replace(`{${"connector"}}`, encodeURIComponent(String(connector)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} connector 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConnectorConfig: async (connector: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'connector' is not null or undefined
            assertParamExists('getConnectorConfig', 'connector', connector)
            const localVarPath = `/connectors/{connector}/config`
                .replace(`{${"connector"}}`, encodeURIComponent(String(connector)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the configuration definition for the specified pluginName
         * @param {string} pluginName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConnectorConfigDef: async (pluginName: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pluginName' is not null or undefined
            assertParamExists('getConnectorConfigDef', 'pluginName', pluginName)
            const localVarPath = `/connector-plugins/{pluginName}/config`
                .replace(`{${"pluginName"}}`, encodeURIComponent(String(pluginName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} connector 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConnectorStatus: async (connector: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'connector' is not null or undefined
            assertParamExists('getConnectorStatus', 'connector', connector)
            const localVarPath = `/connectors/{connector}/status`
                .replace(`{${"connector"}}`, encodeURIComponent(String(connector)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} connector 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOffsets: async (connector: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'connector' is not null or undefined
            assertParamExists('getOffsets', 'connector', connector)
            const localVarPath = `/connectors/{connector}/offsets`
                .replace(`{${"connector"}}`, encodeURIComponent(String(connector)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} connector 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTaskConfigs: async (connector: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'connector' is not null or undefined
            assertParamExists('getTaskConfigs', 'connector', connector)
            const localVarPath = `/connectors/{connector}/tasks`
                .replace(`{${"connector"}}`, encodeURIComponent(String(connector)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} connector 
         * @param {number} task 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTaskStatus: async (connector: string, task: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'connector' is not null or undefined
            assertParamExists('getTaskStatus', 'connector', connector)
            // verify required parameter 'task' is not null or undefined
            assertParamExists('getTaskStatus', 'task', task)
            const localVarPath = `/connectors/{connector}/tasks/{task}/status`
                .replace(`{${"connector"}}`, encodeURIComponent(String(connector)))
                .replace(`{${"task"}}`, encodeURIComponent(String(task)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} connector 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getTasksConfig: async (connector: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'connector' is not null or undefined
            assertParamExists('getTasksConfig', 'connector', connector)
            const localVarPath = `/connectors/{connector}/tasks-config`
                .replace(`{${"connector"}}`, encodeURIComponent(String(connector)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List all connector plugins installed
         * @param {boolean} [connectorsOnly] Whether to list only connectors instead of all plugins
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listConnectorPlugins: async (connectorsOnly?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/connector-plugins`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (connectorsOnly !== undefined) {
                localVarQueryParameter['connectorsOnly'] = connectorsOnly;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List all connector catalog
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listConnectorPluginsCatalog: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/connector-plugins/catalog`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listConnectors: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/connectors`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} connector 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pauseConnector: async (connector: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'connector' is not null or undefined
            assertParamExists('pauseConnector', 'connector', connector)
            const localVarPath = `/connectors/{connector}/pause`
                .replace(`{${"connector"}}`, encodeURIComponent(String(connector)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} connector 
         * @param {boolean} [forward] 
         * @param {{ [key: string]: string; }} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putConnectorConfig: async (connector: string, forward?: boolean, requestBody?: { [key: string]: string; }, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'connector' is not null or undefined
            assertParamExists('putConnectorConfig', 'connector', connector)
            const localVarPath = `/connectors/{connector}/config`
                .replace(`{${"connector"}}`, encodeURIComponent(String(connector)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (forward !== undefined) {
                localVarQueryParameter['forward'] = forward;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} connector 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetConnectorActiveTopics: async (connector: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'connector' is not null or undefined
            assertParamExists('resetConnectorActiveTopics', 'connector', connector)
            const localVarPath = `/connectors/{connector}/topics/reset`
                .replace(`{${"connector"}}`, encodeURIComponent(String(connector)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} connector 
         * @param {boolean} [forward] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetConnectorOffsets: async (connector: string, forward?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'connector' is not null or undefined
            assertParamExists('resetConnectorOffsets', 'connector', connector)
            const localVarPath = `/connectors/{connector}/offsets`
                .replace(`{${"connector"}}`, encodeURIComponent(String(connector)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (forward !== undefined) {
                localVarQueryParameter['forward'] = forward;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} connector 
         * @param {boolean} [includeTasks] 
         * @param {boolean} [onlyFailed] 
         * @param {boolean} [forward] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restartConnector: async (connector: string, includeTasks?: boolean, onlyFailed?: boolean, forward?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'connector' is not null or undefined
            assertParamExists('restartConnector', 'connector', connector)
            const localVarPath = `/connectors/{connector}/restart`
                .replace(`{${"connector"}}`, encodeURIComponent(String(connector)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (includeTasks !== undefined) {
                localVarQueryParameter['includeTasks'] = includeTasks;
            }

            if (onlyFailed !== undefined) {
                localVarQueryParameter['onlyFailed'] = onlyFailed;
            }

            if (forward !== undefined) {
                localVarQueryParameter['forward'] = forward;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} connector 
         * @param {number} task 
         * @param {boolean} [forward] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restartTask: async (connector: string, task: number, forward?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'connector' is not null or undefined
            assertParamExists('restartTask', 'connector', connector)
            // verify required parameter 'task' is not null or undefined
            assertParamExists('restartTask', 'task', task)
            const localVarPath = `/connectors/{connector}/tasks/{task}/restart`
                .replace(`{${"connector"}}`, encodeURIComponent(String(connector)))
                .replace(`{${"task"}}`, encodeURIComponent(String(task)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (forward !== undefined) {
                localVarQueryParameter['forward'] = forward;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} connector 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resumeConnector: async (connector: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'connector' is not null or undefined
            assertParamExists('resumeConnector', 'connector', connector)
            const localVarPath = `/connectors/{connector}/resume`
                .replace(`{${"connector"}}`, encodeURIComponent(String(connector)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serverInfo: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} connector 
         * @param {boolean} [forward] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stopConnector: async (connector: string, forward?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'connector' is not null or undefined
            assertParamExists('stopConnector', 'connector', connector)
            const localVarPath = `/connectors/{connector}/stop`
                .replace(`{${"connector"}}`, encodeURIComponent(String(connector)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (forward !== undefined) {
                localVarQueryParameter['forward'] = forward;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Validate the provided configuration against the configuration definition for the specified pluginName
         * @param {string} pluginName 
         * @param {{ [key: string]: string; }} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateConfigs: async (pluginName: string, requestBody?: { [key: string]: string; }, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pluginName' is not null or undefined
            assertParamExists('validateConfigs', 'pluginName', pluginName)
            const localVarPath = `/connector-plugins/{pluginName}/config/validate`
                .replace(`{${"pluginName"}}`, encodeURIComponent(String(pluginName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} connector 
         * @param {boolean} [forward] 
         * @param {ConnectorOffsets} [connectorOffsets] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async alterConnectorOffsets(connector: string, forward?: boolean, connectorOffsets?: ConnectorOffsets, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.alterConnectorOffsets(connector, forward, connectorOffsets, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.alterConnectorOffsets']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {boolean} [forward] 
         * @param {CreateConnectorRequest} [createConnectorRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createConnector(forward?: boolean, createConnectorRequest?: CreateConnectorRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createConnector(forward, createConnectorRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.createConnector']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} connector 
         * @param {boolean} [forward] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async destroyConnector(connector: string, forward?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.destroyConnector(connector, forward, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.destroyConnector']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} connector 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getConnector(connector: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConnectorInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getConnector(connector, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.getConnector']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} connector 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getConnectorActiveTopics(connector: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getConnectorActiveTopics(connector, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.getConnectorActiveTopics']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} connector 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getConnectorConfig(connector: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: string; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getConnectorConfig(connector, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.getConnectorConfig']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get the configuration definition for the specified pluginName
         * @param {string} pluginName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getConnectorConfigDef(pluginName: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ConfigKeyInfo>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getConnectorConfigDef(pluginName, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.getConnectorConfigDef']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} connector 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getConnectorStatus(connector: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConnectorStateInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getConnectorStatus(connector, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.getConnectorStatus']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} connector 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOffsets(connector: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConnectorOffsets>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOffsets(connector, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.getOffsets']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} connector 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTaskConfigs(connector: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TaskInfo>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTaskConfigs(connector, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.getTaskConfigs']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} connector 
         * @param {number} task 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTaskStatus(connector: string, task: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaskState>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTaskStatus(connector, task, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.getTaskStatus']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} connector 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getTasksConfig(connector: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: { [key: string]: string; }; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTasksConfig(connector, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.getTasksConfig']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary List all connector plugins installed
         * @param {boolean} [connectorsOnly] Whether to list only connectors instead of all plugins
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listConnectorPlugins(connectorsOnly?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PluginInfo>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listConnectorPlugins(connectorsOnly, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.listConnectorPlugins']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary List all connector catalog
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listConnectorPluginsCatalog(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FunctionMeshConnectorDefinition>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listConnectorPluginsCatalog(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.listConnectorPluginsCatalog']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listConnectors(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listConnectors(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.listConnectors']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} connector 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pauseConnector(connector: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pauseConnector(connector, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.pauseConnector']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} connector 
         * @param {boolean} [forward] 
         * @param {{ [key: string]: string; }} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putConnectorConfig(connector: string, forward?: boolean, requestBody?: { [key: string]: string; }, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putConnectorConfig(connector, forward, requestBody, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.putConnectorConfig']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} connector 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resetConnectorActiveTopics(connector: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resetConnectorActiveTopics(connector, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.resetConnectorActiveTopics']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} connector 
         * @param {boolean} [forward] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resetConnectorOffsets(connector: string, forward?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resetConnectorOffsets(connector, forward, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.resetConnectorOffsets']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} connector 
         * @param {boolean} [includeTasks] 
         * @param {boolean} [onlyFailed] 
         * @param {boolean} [forward] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async restartConnector(connector: string, includeTasks?: boolean, onlyFailed?: boolean, forward?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.restartConnector(connector, includeTasks, onlyFailed, forward, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.restartConnector']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} connector 
         * @param {number} task 
         * @param {boolean} [forward] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async restartTask(connector: string, task: number, forward?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.restartTask(connector, task, forward, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.restartTask']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} connector 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resumeConnector(connector: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resumeConnector(connector, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.resumeConnector']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async serverInfo(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ServerInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.serverInfo(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.serverInfo']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} connector 
         * @param {boolean} [forward] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stopConnector(connector: string, forward?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stopConnector(connector, forward, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.stopConnector']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Validate the provided configuration against the configuration definition for the specified pluginName
         * @param {string} pluginName 
         * @param {{ [key: string]: string; }} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async validateConfigs(pluginName: string, requestBody?: { [key: string]: string; }, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConfigInfos>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.validateConfigs(pluginName, requestBody, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.validateConfigs']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * 
         * @param {string} connector 
         * @param {boolean} [forward] 
         * @param {ConnectorOffsets} [connectorOffsets] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        alterConnectorOffsets(connector: string, forward?: boolean, connectorOffsets?: ConnectorOffsets, options?: any): AxiosPromise<void> {
            return localVarFp.alterConnectorOffsets(connector, forward, connectorOffsets, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {boolean} [forward] 
         * @param {CreateConnectorRequest} [createConnectorRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createConnector(forward?: boolean, createConnectorRequest?: CreateConnectorRequest, options?: any): AxiosPromise<void> {
            return localVarFp.createConnector(forward, createConnectorRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} connector 
         * @param {boolean} [forward] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        destroyConnector(connector: string, forward?: boolean, options?: any): AxiosPromise<void> {
            return localVarFp.destroyConnector(connector, forward, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} connector 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConnector(connector: string, options?: any): AxiosPromise<ConnectorInfo> {
            return localVarFp.getConnector(connector, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} connector 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConnectorActiveTopics(connector: string, options?: any): AxiosPromise<void> {
            return localVarFp.getConnectorActiveTopics(connector, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} connector 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConnectorConfig(connector: string, options?: any): AxiosPromise<{ [key: string]: string; }> {
            return localVarFp.getConnectorConfig(connector, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the configuration definition for the specified pluginName
         * @param {string} pluginName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConnectorConfigDef(pluginName: string, options?: any): AxiosPromise<Array<ConfigKeyInfo>> {
            return localVarFp.getConnectorConfigDef(pluginName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} connector 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConnectorStatus(connector: string, options?: any): AxiosPromise<ConnectorStateInfo> {
            return localVarFp.getConnectorStatus(connector, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} connector 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOffsets(connector: string, options?: any): AxiosPromise<ConnectorOffsets> {
            return localVarFp.getOffsets(connector, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} connector 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTaskConfigs(connector: string, options?: any): AxiosPromise<Array<TaskInfo>> {
            return localVarFp.getTaskConfigs(connector, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} connector 
         * @param {number} task 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTaskStatus(connector: string, task: number, options?: any): AxiosPromise<TaskState> {
            return localVarFp.getTaskStatus(connector, task, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} connector 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getTasksConfig(connector: string, options?: any): AxiosPromise<{ [key: string]: { [key: string]: string; }; }> {
            return localVarFp.getTasksConfig(connector, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List all connector plugins installed
         * @param {boolean} [connectorsOnly] Whether to list only connectors instead of all plugins
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listConnectorPlugins(connectorsOnly?: boolean, options?: any): AxiosPromise<Array<PluginInfo>> {
            return localVarFp.listConnectorPlugins(connectorsOnly, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List all connector catalog
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listConnectorPluginsCatalog(options?: any): AxiosPromise<Array<FunctionMeshConnectorDefinition>> {
            return localVarFp.listConnectorPluginsCatalog(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listConnectors(options?: any): AxiosPromise<void> {
            return localVarFp.listConnectors(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} connector 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pauseConnector(connector: string, options?: any): AxiosPromise<void> {
            return localVarFp.pauseConnector(connector, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} connector 
         * @param {boolean} [forward] 
         * @param {{ [key: string]: string; }} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putConnectorConfig(connector: string, forward?: boolean, requestBody?: { [key: string]: string; }, options?: any): AxiosPromise<void> {
            return localVarFp.putConnectorConfig(connector, forward, requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} connector 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetConnectorActiveTopics(connector: string, options?: any): AxiosPromise<void> {
            return localVarFp.resetConnectorActiveTopics(connector, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} connector 
         * @param {boolean} [forward] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetConnectorOffsets(connector: string, forward?: boolean, options?: any): AxiosPromise<void> {
            return localVarFp.resetConnectorOffsets(connector, forward, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} connector 
         * @param {boolean} [includeTasks] 
         * @param {boolean} [onlyFailed] 
         * @param {boolean} [forward] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restartConnector(connector: string, includeTasks?: boolean, onlyFailed?: boolean, forward?: boolean, options?: any): AxiosPromise<void> {
            return localVarFp.restartConnector(connector, includeTasks, onlyFailed, forward, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} connector 
         * @param {number} task 
         * @param {boolean} [forward] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restartTask(connector: string, task: number, forward?: boolean, options?: any): AxiosPromise<void> {
            return localVarFp.restartTask(connector, task, forward, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} connector 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resumeConnector(connector: string, options?: any): AxiosPromise<void> {
            return localVarFp.resumeConnector(connector, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serverInfo(options?: any): AxiosPromise<ServerInfo> {
            return localVarFp.serverInfo(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} connector 
         * @param {boolean} [forward] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stopConnector(connector: string, forward?: boolean, options?: any): AxiosPromise<void> {
            return localVarFp.stopConnector(connector, forward, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Validate the provided configuration against the configuration definition for the specified pluginName
         * @param {string} pluginName 
         * @param {{ [key: string]: string; }} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateConfigs(pluginName: string, requestBody?: { [key: string]: string; }, options?: any): AxiosPromise<ConfigInfos> {
            return localVarFp.validateConfigs(pluginName, requestBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * 
     * @param {string} connector 
     * @param {boolean} [forward] 
     * @param {ConnectorOffsets} [connectorOffsets] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public alterConnectorOffsets(connector: string, forward?: boolean, connectorOffsets?: ConnectorOffsets, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).alterConnectorOffsets(connector, forward, connectorOffsets, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {boolean} [forward] 
     * @param {CreateConnectorRequest} [createConnectorRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createConnector(forward?: boolean, createConnectorRequest?: CreateConnectorRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).createConnector(forward, createConnectorRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} connector 
     * @param {boolean} [forward] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public destroyConnector(connector: string, forward?: boolean, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).destroyConnector(connector, forward, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} connector 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getConnector(connector: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getConnector(connector, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} connector 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getConnectorActiveTopics(connector: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getConnectorActiveTopics(connector, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} connector 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getConnectorConfig(connector: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getConnectorConfig(connector, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the configuration definition for the specified pluginName
     * @param {string} pluginName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getConnectorConfigDef(pluginName: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getConnectorConfigDef(pluginName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} connector 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getConnectorStatus(connector: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getConnectorStatus(connector, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} connector 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getOffsets(connector: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getOffsets(connector, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} connector 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getTaskConfigs(connector: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getTaskConfigs(connector, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} connector 
     * @param {number} task 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getTaskStatus(connector: string, task: number, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getTaskStatus(connector, task, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} connector 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getTasksConfig(connector: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getTasksConfig(connector, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List all connector plugins installed
     * @param {boolean} [connectorsOnly] Whether to list only connectors instead of all plugins
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listConnectorPlugins(connectorsOnly?: boolean, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).listConnectorPlugins(connectorsOnly, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List all connector catalog
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listConnectorPluginsCatalog(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).listConnectorPluginsCatalog(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listConnectors(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).listConnectors(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} connector 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public pauseConnector(connector: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).pauseConnector(connector, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} connector 
     * @param {boolean} [forward] 
     * @param {{ [key: string]: string; }} [requestBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public putConnectorConfig(connector: string, forward?: boolean, requestBody?: { [key: string]: string; }, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).putConnectorConfig(connector, forward, requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} connector 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public resetConnectorActiveTopics(connector: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).resetConnectorActiveTopics(connector, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} connector 
     * @param {boolean} [forward] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public resetConnectorOffsets(connector: string, forward?: boolean, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).resetConnectorOffsets(connector, forward, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} connector 
     * @param {boolean} [includeTasks] 
     * @param {boolean} [onlyFailed] 
     * @param {boolean} [forward] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public restartConnector(connector: string, includeTasks?: boolean, onlyFailed?: boolean, forward?: boolean, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).restartConnector(connector, includeTasks, onlyFailed, forward, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} connector 
     * @param {number} task 
     * @param {boolean} [forward] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public restartTask(connector: string, task: number, forward?: boolean, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).restartTask(connector, task, forward, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} connector 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public resumeConnector(connector: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).resumeConnector(connector, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public serverInfo(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).serverInfo(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} connector 
     * @param {boolean} [forward] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public stopConnector(connector: string, forward?: boolean, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).stopConnector(connector, forward, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Validate the provided configuration against the configuration definition for the specified pluginName
     * @param {string} pluginName 
     * @param {{ [key: string]: string; }} [requestBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public validateConfigs(pluginName: string, requestBody?: { [key: string]: string; }, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).validateConfigs(pluginName, requestBody, options).then((request) => request(this.axios, this.basePath));
    }
}



