import {
  useTenantsPulsarAdmin,
  useTenantsPulsarAdminWithSpecificInstance
} from '@/composables/pulsarAdmin'
import type { TenantInfo } from '@streamnative/pulsar-admin-client-typescript'
import { useCloudApi } from '@/composables/cloudApi'
import { tenantsAdminApiIsAvailable, usePluginsApi } from '@/composables/pulsarAdmin'

const buildTenantsPulsarAdmin = (
  organization: string | undefined = usePulsarState().organization.value,
  clusterUid: string | undefined = usePulsarState().clusterUid.value
) => {
  if (!organization) {
    throw Error(`Organization is not set.`)
  }
  if (!clusterUid) {
    throw Error(`Cluster is not set.`)
  }
  return useTenantsPulsarAdmin(organization, clusterUid)
}

const buildPluginTenantsPulsarAdmin = (
  organization: string | undefined = usePulsarState().organization.value,
  clusterUid: string | undefined = usePulsarState().clusterUid.value,
  instance: string | undefined = usePulsarState().instance.value
) => {
  return usePluginsApi(organization, clusterUid, instance)
}

export const fetchTenants = async ({
  organization,
  clusterUid
}: {
  organization?: string
  clusterUid?: string
}) => {
  try {
    const tenantsPulsarAdmin = buildTenantsPulsarAdmin(organization, clusterUid)
    return await tenantsPulsarAdmin.get()
  } catch (e) {
    throw getErrorMessage(e, 'fetchTenants Error')
  }
}

export const fetchAdministeredTenants = async (
  organization: string,
  clusterUid: string,
  instance: string
) => {
  if (await tenantsAdminApiIsAvailable(organization, clusterUid, instance)) {
    const pluginAdmin = buildPluginTenantsPulsarAdmin(organization, clusterUid, instance)
    return await pluginAdmin.getAdminTenants()
  }
}

export const fetchTenantsForSpecificInstance = async ({
  organization,
  instance,
  clusterUid
}: {
  organization?: string
  instance?: string
  clusterUid?: string
}) => {
  if (organization === undefined || instance === undefined || clusterUid === undefined) {
    throw Error('Invalid parameters for fetchTenantsForSpecificInstance')
  }
  const api = useTenantsPulsarAdminWithSpecificInstance(organization, clusterUid, instance)
  return await api.get()
}

export const fetchTenantAdminWithSpecificInstance = async ({
  organization,
  clusterUid,
  instance,
  tenant
}: {
  organization: string
  clusterUid: string
  instance: string
  tenant: string
}) => {
  try {
    const api = useTenantsPulsarAdminWithSpecificInstance(organization, clusterUid, instance)
    return await api.getTenantAdmin(tenant)
  } catch (e) {
    throw getErrorMessage(e, 'fetchTenantAdminWithSpecificInstance Error')
  }
}

export const updateTenantAdminWithSpecificInstance = async ({
  organization,
  clusterUid,
  instance,
  tenant,
  data
}: {
  organization: string
  clusterUid: string
  instance: string
  tenant: string
  data: TenantInfo
}) => {
  try {
    if (tenant === '' || !data) {
      throw new Error('Cannot update tenant admin without tenant or data')
    }

    const api = useTenantsPulsarAdminWithSpecificInstance(organization, clusterUid, instance)
    return await api.updateTenant(tenant, data)
  } catch (e) {
    throw getErrorMessage(e, 'fetchTenantAdminWithSpecificInstance Error')
  }
}

export const fetchTenantAdmin = async ({
  organization,
  clusterUid,
  tenant
}: {
  organization?: string
  clusterUid?: string
  tenant: string
}) => {
  try {
    const tenantsPulsarAdmin = buildTenantsPulsarAdmin(organization, clusterUid)
    return await tenantsPulsarAdmin.getTenantAdmin(tenant)
  } catch (e) {
    throw getErrorMessage(e, 'fetchTenantAdmin Error')
  }
}

// returns the following
// {
//   instanceName: { clusters: [{tenant, uid, name, adminRoles}] }
// }
//
export const getAdminsForTenants = async (
  organization: string
): Promise<
  Record<
    string,
    { clusters: Array<{ name: string; tenant: string; uid: string; adminRoles: Array<string> }> }
  >
> => {
  const { data } = await useCloudApi().listNamespacedPulsarCluster(organization)
  const clusters = data.items

  const tenInCluster: Record<
    string,
    { clusters: Array<{ tenant: string; uid: string; name: string; adminRoles: Array<string> }> }
  > = {}
  for (const cluster of clusters) {
    const instance = cluster.spec?.instanceName
    if (!instance) {
      throw new Error('Instance for cluster is invalid')
    }
    if (!cluster?.metadata?.uid) {
      throw new Error('Cluster uid is undefined, unable to continue')
    }
    if (!cluster.metadata.name) {
      throw new Error('Cluster has no name, error')
    }

    // this will be slow due to duplicates from clusters having same instance
    const ftRes = await fetchTenantsForSpecificInstance({
      organization,
      instance,
      clusterUid: cluster.metadata.uid
    })

    const tenantAdmins = await Promise.all(
      ftRes.data.map((resTenant: string) => {
        if (!cluster.metadata?.uid) {
          throw new Error('Cluster is invalid. No UID.')
        }

        if (!cluster.metadata?.name) {
          throw new Error('Cluster is invalid. No Name.')
        }

        return fetchTenantAdminWithSpecificInstance({
          organization,
          clusterUid: cluster.metadata.uid,
          instance,
          tenant: resTenant
        })
      })
    )

    // results should be in order between both calls so
    // it should be fine to just iterate over it again
    for (let i = 0; i < tenantAdmins.length; i++) {
      const resTenant = ftRes.data[i]

      if (!tenInCluster[instance]) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        tenInCluster[instance] = {}
        tenInCluster[instance].clusters = []
      }

      tenInCluster[instance].clusters.push({
        uid: cluster.metadata.uid,
        name: cluster.metadata.name,
        adminRoles: tenantAdmins[i].data.adminRoles || [],
        tenant: resTenant
      })
    }
  }

  return tenInCluster
}

export const getUsersListOfAdministeredTenants = async (
  organization: string,
  user: string
): Promise<
  | Array<{
      instance?: string | undefined
      tenantsToClusters?: Array<{
        clusters: Array<{ uid: string | undefined; name: string | undefined }>
        tenant: string | undefined
        label: string | undefined
      }>
    }>
  | undefined
> => {
  const listOfTenInCluster: Array<{
    instance?: string | undefined
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    tenantsToClusters?: Array<any>
  }> = []

  const { data } = await useCloudApi().listNamespacedPulsarCluster(organization)
  const clusters = data.items

  //      for each of these clusters you have to keep track
  //      of their instance and adjust the client you are using
  //      to get the right token to actually make this call

  // The label is subject to change. We may continue to modify it in the
  // future.

  //         {
  //           "instance": "test",
  //           "tenantsToClusters": [
  //             {
  //               "clusters": [
  //                  {
  //                    uid: "77615588-6bc9-4479-9fc2-19ee31155d0e",
  //                    name: "test
  //                  }
  //               ],
  //               "tenant": "test",
  //               "label": "test"
  //             }
  //           ]
  //         }
  //       ]
  //     }
  //   },
  for (const cluster of clusters) {
    const instance = cluster.spec?.instanceName
    if (!instance) {
      throw new Error('Instance is undefined for cluster, error')
    }

    // this will be slow due to duplicates from clusters having same instance
    const ftRes = await fetchTenantsForSpecificInstance({
      organization,
      instance,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      clusterUid: cluster.metadata.uid
    })

    for (const tenant of ftRes.data) {
      const tenantAdmins = await fetchTenantAdminWithSpecificInstance({
        organization,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        clusterUid: cluster.metadata.uid,
        instance,
        tenant
      })

      // check if user is in list, if so add to tenantsAndClusters
      if (tenantAdmins?.data?.adminRoles?.includes(user)) {
        // find if listOfTenInCluster has the instance
        const instanceIndex = listOfTenInCluster.findIndex(itc => {
          return itc['instance'] === instance
        })

        // if so, manipulate this instance to tenant+cluster list
        if (instanceIndex > -1) {
          // you need to find the tenant that this cluster belongs to and the index in the list the belongs to
          const tenantIndex = listOfTenInCluster[instanceIndex]['tenantsToClusters']?.findIndex(
            tcl => {
              return tcl['tenant'] === tenant
            }
          )

          if (tenantIndex !== undefined && tenantIndex > -1) {
            // then append the cluster to that list for that tenant
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            listOfTenInCluster[instanceIndex]['tenantsToClusters'][tenantIndex]['clusters']?.push({
              uid: cluster.metadata?.uid,
              name: cluster.metadata?.name
            })
          } else {
            // if you cant find that tenant in this instance, create it with the label and a new cluster list with this cluster
            // this cant be undefined because of the other else, the compiler is
            // a bit annoying on this regard, hence the ignores

            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            listOfTenInCluster[instanceIndex]['tenantsToClusters']?.push({})
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            const length = listOfTenInCluster[instanceIndex]['tenantsToClusters']?.length - 1
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            if (!listOfTenInCluster[instanceIndex]['tenantsToClusters'][length]['clusters']) {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              //@ts-ignore
              listOfTenInCluster[instanceIndex]['tenantsToClusters'][length]['clusters'] = []
            }
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            listOfTenInCluster[instanceIndex]['tenantsToClusters'][length]['clusters'].push({
              uid: cluster.metadata?.uid,
              name: cluster.metadata?.name
            })
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            listOfTenInCluster[instanceIndex]['tenantsToClusters'][length]['tenant'] = tenant
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            listOfTenInCluster[instanceIndex]['tenantsToClusters'][length]['label'] = `${tenant}`
          }
        } else {
          // otherwise, create a new instance in the array

          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          const tenInCluster: any = {}
          tenInCluster['instance'] = instance
          if (!tenInCluster['tenantsToClusters']) {
            tenInCluster['tenantsToClusters'] = []
          }

          tenInCluster['tenantsToClusters'].push({})
          const length = tenInCluster['tenantsToClusters'].length - 1
          if (!tenInCluster['tenantsToClusters'][length]['clusters']) {
            tenInCluster['tenantsToClusters'][length]['clusters'] = []
          }
          tenInCluster['tenantsToClusters'][length]['clusters'].push({
            uid: cluster.metadata?.uid,
            name: cluster.metadata?.name
          })
          tenInCluster['tenantsToClusters'][length]['tenant'] = tenant
          tenInCluster['tenantsToClusters'][length]['label'] = `${tenant}`

          // for some reason this returns undefined no matter what even if it doesnt run
          // the above code
          if (tenInCluster['instance'] && tenInCluster['tenantsToClusters']) {
            listOfTenInCluster.push(tenInCluster)
          }
        }
      }
    }
  }

  if (listOfTenInCluster.length === 0) {
    return undefined
  }

  return listOfTenInCluster
}

export const createTenant = async ({
  organization,
  clusterUid,
  tenant,
  data
}: {
  organization?: string
  clusterUid?: string
  tenant: string
  data: TenantInfo
}) => {
  try {
    const tenantsPulsarAdmin = buildTenantsPulsarAdmin(organization, clusterUid)
    return await tenantsPulsarAdmin.createTenant(tenant, data)
  } catch (e) {
    throw getErrorMessage(e, 'createTenant Error')
  }
}

export const updateTenant = async ({
  organization,
  clusterUid,
  tenant,
  data
}: {
  organization?: string
  clusterUid?: string
  tenant: string
  data: TenantInfo
}) => {
  try {
    const tenantsPulsarAdmin = buildTenantsPulsarAdmin(organization, clusterUid)
    return await tenantsPulsarAdmin.updateTenant(tenant, data)
  } catch (e) {
    throw getErrorMessage(e, 'updateTenant Error')
  }
}

export const deleteTenant = async ({
  organization,
  clusterUid,
  tenant
}: {
  organization?: string
  clusterUid?: string
  tenant: string
}) => {
  try {
    const tenantsPulsarAdmin = buildTenantsPulsarAdmin(organization, clusterUid)
    return await tenantsPulsarAdmin.deleteTenant(tenant)
  } catch (e) {
    throw getErrorMessage(e, 'createTenant Error')
  }
}
