import {
  createAuth0,
  type LogoutOptions,
  authGuard as authGuardLib,
  createAuthGuard as _createAuthGuard
} from '@auth0/auth0-vue'
import authConfig from 'Config/auth_config.json'
import {
  clearLastViewedOrgIns,
  clearCachedOrgMetadata,
  clearUserMetadataSetKey
} from '@/utils/localStorageHelper'

const urlParams = new URLSearchParams(window.location.search)
const defaultMethod = urlParams.get('defaultMethod')
const _auth = createAuth0(
  {
    domain: authConfig.domain,
    clientId: authConfig.clientId,
    authorizationParams: {
      redirect_uri: authConfig.callbackUrl,
      screen_hint: defaultMethod ? (defaultMethod as string).toLowerCase() : undefined,
      audience: authConfig.audience
    },
    cacheLocation: 'localstorage',
    // `useRefreshTokens` will break our e2e tests.  Since safari is the only one that needs
    // this value to be true, we will conditionally set this value to true only for safari.
    useRefreshTokens: /^((?!chrome|android).)*safari/i.test(navigator.userAgent) ? true : false,
    useRefreshTokensFallback: true
  },
  {
    skipRedirectCallback: location.pathname === '/callback' && location.search.includes('error=')
  }
)
const originalLogout = _auth.logout
_auth.logout = async (options?: LogoutOptions) => {
  clearLastViewedOrgIns()
  clearUserMetadataSetKey()
  clearCachedOrgMetadata()
  await originalLogout({
    ...options,
    logoutParams: {
      returnTo: `${authConfig.logoutCallback}`
    }
  })
}

export const auth = _auth
export const authGuard = authGuardLib
export const createAuthGuard = _createAuthGuard
