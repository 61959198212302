import type {
  NamespacesApi,
  NonPersistentTopicApi,
  PersistentTopicApi,
  TenantsApi
} from '@streamnative/pulsar-admin-client-typescript'
import * as cloud from '@streamnative/pulsar-admin-client-typescript'
import { auth } from '@/auth'
import getAxiosInstance from '@/utils/axios'
import proxyConfig from 'Config/proxy_config.json'
import retry from 'async-retry'
import axios, { AxiosError, HttpStatusCode } from 'axios'

const TENANTS_PARTITIONED = '/admin/v2/persistent/(.*)/(.*)/(.*)/partitions'
const authInterceptedInstance = getAxiosInstance()

const availabilityCheckInstance = axios.create({ timeout: 10000 })
availabilityCheckInstance.interceptors.request.use(async config => {
  return configureAxiosRequestInterceptor(getPulsarAccessToken, config)
})

const getPulsarAccessToken = () => {
  const { audience } = useInstance()

  return retry(
    async () => {
      return await getTokenForAudience(audience.value)
    },
    { retries: 3, minTimeout: 1000, factor: 2 }
  )
}

const getTokenForAudience = async (audience: string | undefined) => {
  const { getAccessTokenSilently } = auth
  // For access token for pulsar, we use getAccessTokenSilently().  But for this function
  // to work, authentication to our cloud is needed.  And it is possible that we get here
  // without cloud authentication is done.  Instead of implementing complex awaits and
  // dependencies, we are just going to do a simple retry with backoff timeout.
  const token = await getAccessTokenSilently({
    authorizationParams: {
      audience: audience,
      scope: 'admin'
    }
  })

  if (token.length > 50 && token.indexOf('..') < 0) {
    return token
  } else {
    throw Error('invalid token')
  }
}

const getPulsarAccessTokenForSpecificInstance = ({
  organization,
  instance
}: {
  organization: string
  instance: string
}) => {
  // TODO pull audience directly from queried instance  activeInstance.value.status?.auth?.oauth2?.audience
  return retry(
    async () => {
      return await getTokenForAudience(`urn:sn:pulsar:${organization}:${instance}`)
    },
    { retries: 3, minTimeout: 800, factor: 2 }
  )
}

const configureAxiosRequestInterceptor = async (
  tokenFunction: any,
  config: any,
  tokenParameters: any | undefined = undefined
) => {
  if (tokenParameters) {
    config.headers['Authorization'] = `Bearer ${await tokenFunction(tokenParameters)}`
  } else {
    config.headers['Authorization'] = `Bearer ${await tokenFunction()}`
  }

  // openapi-generator from which the pulsar-admin client is generated has a bug
  // which does not allow for plain integer parameters to be passed to certain
  // json endpoints (see https://github.com/OpenAPITools/openapi-generator/issues/10991 for
  // details)
  // this is a workaround for this particular problem by simply converting the request data
  // to the proper format for problematic endpoints
  if ((config.url || '').search(TENANTS_PARTITIONED) >= 0 && config.data) {
    config.data = parseInt(JSON.parse(config.data))
  }

  return config
}

authInterceptedInstance.interceptors.request.use(async config => {
  return (config = await configureAxiosRequestInterceptor(getPulsarAccessToken, config))
})

export const getBasePath = (org: string, clusterId: string): string =>
  proxyConfig.proxyLocation + `/pulsar-admin/${org}/pulsarcluster-${clusterId}`

export const useTenantsPulsarAdminWithSpecificInstance = (
  organization: string,
  clusterId: string,
  instance: string
) => {
  if (organization === '' || clusterId === '' || instance === '') {
    throw new Error('Unable to create tenant client for specific instance, bad parameters')
  }
  const newAxios = getAxiosInstance()
  newAxios.interceptors.request.use(async config => {
    return (config = await configureAxiosRequestInterceptor(
      getPulsarAccessTokenForSpecificInstance,
      config,
      { organization, instance }
    ))
  })
  return new cloud.TenantsApi(undefined, getBasePath(organization, clusterId), newAxios)
}

export const usePluginsPulsarAdminWithSpecificInstance = (
  organization: string | undefined,
  clusterId: string | undefined,
  instance: string | undefined
) => {
  if (
    organization === '' ||
    clusterId === '' ||
    instance === '' ||
    !organization ||
    !clusterId ||
    !instance
  ) {
    throw new Error('Unable to create plugin client for specific instance, bad parameters')
  }
  const newAxios = getAxiosInstance()
  newAxios.interceptors.request.use(async config => {
    return (config = await configureAxiosRequestInterceptor(
      getPulsarAccessTokenForSpecificInstance,
      config,
      { organization, instance }
    ))
  })
  const pluginsApi = new cloud.PluginsApi(undefined, getBasePath(organization, clusterId), newAxios)

  return pluginsApi
}

export const useFastFailPluginsPulsarAdminWithSpecificInstance = (
  organization: string | undefined,
  clusterId: string | undefined,
  instance: string | undefined
) => {
  if (
    organization === '' ||
    clusterId === '' ||
    instance === '' ||
    !organization ||
    !clusterId ||
    !instance
  ) {
    throw new Error('Unable to create plugin client for specific instance, bad parameters')
  }

  const axiosInstance = axios.create({ timeout: 4000 })
  axiosInstance.interceptors.response.use(response => {
    return response
  })

  axiosInstance.interceptors.request.use(
    async config => {
      return (config = await configureAxiosRequestInterceptor(
        async () => getTokenForAudience(`urn:sn:pulsar:${organization}:${instance}`),
        config,
        { organization, instance }
      ))
    },
    async e => {
      if (e?.response?.status === 404) {
        return Promise.reject()
      }

      return Promise.reject(e)
    }
  )

  const pluginsApi = new cloud.PluginsApi(
    undefined,
    getBasePath(organization, clusterId),
    axiosInstance
  )

  return pluginsApi
}

export const useTenantsPulsarAdmin = (org: string, clusterId: string): TenantsApi => {
  return new cloud.TenantsApi(undefined, getBasePath(org, clusterId), authInterceptedInstance)
}

export const useNamespacesPulsarAdmin = (org: string, clusterId: string): NamespacesApi => {
  return new cloud.NamespacesApi(undefined, getBasePath(org, clusterId), authInterceptedInstance)
}

export const usePersistentTopicPulsarAdmin = (
  org: string,
  clusterId: string
): PersistentTopicApi => {
  return new cloud.PersistentTopicApi(
    undefined,
    getBasePath(org, clusterId),
    authInterceptedInstance
  )
}

export const useNonPersistentTopicPulsarAdmin = (
  org: string,
  clusterId: string
): NonPersistentTopicApi => {
  return new cloud.NonPersistentTopicApi(
    undefined,
    getBasePath(org, clusterId),
    authInterceptedInstance
  )
}

export const getTopicPulsarAdmin = (
  persitency: boolean,
  org: string,
  clusterId: string
): cloud.PersistentTopicApi | cloud.NonPersistentTopicApi => {
  if (persitency) {
    return usePersistentTopicPulsarAdmin(org, clusterId)
  } else {
    return useNonPersistentTopicPulsarAdmin(org, clusterId)
  }
}

export const getTopicPulsarAdminFromTopic = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  topic: any
): cloud.PersistentTopicApi | cloud.NonPersistentTopicApi => {
  return getTopicPulsarAdmin(topic.persistent, topic.organization, topic.clusterUid)
}

export const useSchemaPulsarAdmin = (org: string, clusterId: string): cloud.SchemasApi => {
  return new cloud.SchemasApi(undefined, getBasePath(org, clusterId), authInterceptedInstance)
}

export const useSinkApi = (): cloud.SinksApi => {
  const { organization, clusterUid } = usePulsarState()
  if (!organization.value || !clusterUid.value) {
    throw new Error('Organization or clusterUid is not set')
  }

  return new cloud.SinksApi(
    undefined,
    getBasePath(organization.value, clusterUid.value),
    authInterceptedInstance
  )
}

export const useSourceApi = (): cloud.SourcesApi => {
  const { organization, clusterUid } = usePulsarState()
  if (!organization.value || !clusterUid.value) {
    throw new Error('Organization or clusterUid is not set')
  }

  return new cloud.SourcesApi(
    undefined,
    getBasePath(organization.value, clusterUid.value),
    authInterceptedInstance
  )
}

export const useFunctionsApi = (): cloud.FunctionsApi => {
  const { organization, clusterUid } = usePulsarState()
  if (!organization.value || !clusterUid.value) {
    throw new Error('Organization or clusterUid is not set')
  }

  return new cloud.FunctionsApi(
    undefined,
    getBasePath(organization.value, clusterUid.value),
    authInterceptedInstance
  )
}

export const usePluginsApi = (
  org: string | undefined,
  cluster: string | undefined,
  instance: string | undefined
): cloud.PluginsApi => {
  if (!org || !cluster || !instance) {
    throw new Error('Organization or cluster or instance is not set')
  }

  return usePluginsPulsarAdminWithSpecificInstance(org, cluster, instance)
}

export const tenantsAdminApiIsAvailable = async (
  org: string | undefined,
  cluster: string | undefined,
  instance: string | undefined
): Promise<boolean> => {
  try {
    // If this call succeeds we have our answer and don't need the response.
    const result = await useFastFailPluginsPulsarAdminWithSpecificInstance(
      org,
      cluster,
      instance
    ).getAdminTenants()

    return !!result
  } catch (e) {
    return false
  }
}
