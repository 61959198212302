import axios from 'axios'
import { auth } from '@/auth'

// TODO tests fail with this timeout :'(
export default function getAxiosInstance() {
  // 10 seconds is too long for a timeout. we should work on our apiservers to make them faster
  const axiosInstance = axios.create({ timeout: 10000 })
  axiosInstance.interceptors.response.use(
    response => {
      return response
    },
    async e => {
      const { isAuthenticated, logout } = auth
      if (
        (e?.response?.status === 401 && !isAuthenticated.value) ||
        e?.error === 'login_required'
      ) {
        await logout()
      }

      return Promise.reject(e)
    }
  )
  return axiosInstance
}
