import { useAuthApi } from '@/composables/cloudApi'

export const fetchSelfSubjectUserReview = () => {
  try {
    const api = useAuthApi()
    return api.createSelfSubjectUserReview({}) // need dummy object
  } catch (e) {
    // return
    throw getErrorMessage(e, 'fetch SelfSubjectUserReview Error')
  }
}

export const fetchSelfSubjectRulesReview = (namespace: string) => {
  try {
    const api = useAuthApi()
    return api.createSelfSubjectRulesReview({ spec: { namespace } })
  } catch (e) {
    throw getErrorMessage(e, 'fetch SelfSubjectRulesReview Error')
  }
}
