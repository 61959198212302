import type {
  V1alpha1ServiceAccount,
  V1alpha1ServiceAccountList,
  V1Status
} from '@streamnative/cloud-api-client-typescript'
import { useCloudApi } from '@/composables/cloudApi'
import type {
  CloudV1alpha1PoolMemberReference,
  V1alpha1RoleBinding,
  V1alpha1ServiceAccountBinding,
  V1alpha1ServiceAccountBindingSpec
} from '@streamnative/cloud-api-client-typescript/api'
import { i18n } from '@/lang'
const { t } = i18n.global
const API_VERSION = 'cloud.streamnative.io/v1alpha1'
import axios from 'axios'
import { AxiosError } from 'axios'
import type { Auth0ClientFlowPayload } from '@/composables/useServiceAccount'

const axoisService = axios.create({ timeout: 60000 })

export const _fetchServiceAccounts = async (
  organization: string
): Promise<V1alpha1ServiceAccountList> => {
  try {
    const api = useCloudApi()
    const res = await api.listNamespacedServiceAccount(organization)
    return res.data
  } catch (e) {
    throw Error(getErrorMessage(e, 'fetchServiceAccounts Error'))
  }
}

export const _createServiceAccount = async (
  organization: string,
  name: string,
  isAdmin: boolean
): Promise<V1alpha1ServiceAccount> => {
  try {
    const api = useCloudApi()
    const newAccount: V1alpha1ServiceAccount = {
      apiVersion: API_VERSION,
      kind: 'ServiceAccount',
      metadata: {
        name: name,
        namespace: organization
      },
      spec: {},
      status: {}
    }

    if (!isAdmin) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore defined on line 31
      newAccount.metadata.annotations = {
        'annotations.cloud.streamnative.io/service-account-role': ''
      }
    } else {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore defined on line 31
      newAccount.metadata.annotations = {
        'annotations.cloud.streamnative.io/service-account-role': 'admin'
      }
    }
    let res = await api.createNamespacedServiceAccount(organization, newAccount)
    if (res === undefined) {
      throw Error(t('serviceAccount.errorCreating'))
    }
    if (isAdmin) {
      // create rolebinding
      const roleBinding: V1alpha1RoleBinding = {
        apiVersion: API_VERSION,
        kind: 'RoleBinding',
        metadata: {
          name: name,
          namespace: organization
        },
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore defined on line 31
        spec: {
          roleRef: {
            apiGroup: 'cloud.streamnative.io',
            kind: 'Role',
            name: 'admin'
          },
          subjects: [
            {
              apiGroup: 'cloud.streamnative.io',
              kind: 'ServiceAccount',
              name: name
            }
          ]
        }
      }
      if (
        res.data.metadata?.uid &&
        res.data.metadata?.name &&
        res.data.kind &&
        res.data.apiVersion &&
        roleBinding.metadata // object is defined on line 65
      ) {
        roleBinding.metadata.ownerReferences = [
          {
            apiVersion: res.data.apiVersion,
            uid: res.data.metadata?.uid,
            name: res.data.metadata?.name,
            kind: res.data.kind
          }
        ]
      }
      res = await api.createNamespacedRoleBinding(organization, roleBinding)
      if (res === undefined) {
        throw Error(t('serviceAccount.errorCreating'))
      }
      if (res.status >= 400) {
        throw Error(t('serviceAccount.errorRoleBinding'))
      }
    }
    return res.data
  } catch (e) {
    throw Error(getErrorMessage(e, t('serviceAccount.errorCreating')))
  }
}

export const _deleteServiceAccount = async (
  organization: string,
  name: string
): Promise<V1Status> => {
  try {
    const api = useCloudApi()
    try {
      await api.deleteNamespacedRoleBinding(name, organization)
    } catch (e) {
      if (e instanceof AxiosError && e?.response?.data?.code !== 404) {
        // 404 means it doesn't exists.  Either it is already deleted or never existed.
        throw e
      }
    }
    const res = await api.deleteNamespacedServiceAccount(
      name,
      organization,
      undefined,
      undefined,
      undefined,
      undefined,
      'Foreground',
      {
        propagationPolicy: 'Foreground'
      }
    )
    return res.data
  } catch (e) {
    throw Error(getErrorMessage(e, 'deleteServiceAccount Error'))
  }
}

export const _getServiceAccount = async (
  organization: string,
  name: string
): Promise<V1alpha1ServiceAccount> => {
  try {
    const api = useCloudApi()
    const res = await api.readNamespacedServiceAccount(name, organization)
    return res.data
  } catch (e) {
    throw Error(getErrorMessage(e, 'getServiceAccount Error'))
  }
}

const getSABindingName = (saName: string, poolMemberRef: CloudV1alpha1PoolMemberReference) => {
  return `${saName}.${poolMemberRef.namespace}.${poolMemberRef.name}`.slice(0, 253)
}

export function _auth0ClientCredentialsFlow(issuerUrl: string, body: Auth0ClientFlowPayload) {
  return axoisService({
    headers: { 'Content-Type': 'application/json' },
    url: `${issuerUrl.endsWith('/') ? issuerUrl : issuerUrl.concat('/')}oauth/token`,
    data: body,
    method: 'post'
  })
}

export const _createServiceAccountBinding = async (
  saName: string,
  namespace: string,
  poolMemberRef: CloudV1alpha1PoolMemberReference
) => {
  const saBindingName = getSABindingName(saName, poolMemberRef)

  const body: V1alpha1ServiceAccountBinding = {
    apiVersion: 'cloud.streamnative.io/v1alpha1',
    kind: 'ServiceAccountBinding',
    metadata: {
      name: saBindingName,
      namespace: namespace
    },
    spec: {
      serviceAccountName: saName,
      poolMemberRef
    } as V1alpha1ServiceAccountBindingSpec
  }

  const api = useCloudApi()
  const res = await api.replaceNamespacedServiceAccountBinding(saBindingName, namespace, body)
  return res.data
}

export const _deleteServiceAccountBinding = async (
  saName: string,
  namespace: string,
  poolMemberRef: CloudV1alpha1PoolMemberReference
) => {
  const saBindingName = getSABindingName(saName, poolMemberRef)

  const api = useCloudApi()
  await api.deleteNamespacedServiceAccountBinding(saBindingName, namespace)
}

export const _listServiceAccountBinding = async (namespace: string) => {
  const api = useCloudApi()
  const res = await api.listNamespacedServiceAccountBinding(namespace)

  return res.data.items
}
