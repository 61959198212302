import { useCloudApi } from '@/composables/cloudApi'
import type { V1alpha1Secret } from '@streamnative/cloud-api-client-typescript'
import { i18n } from '@/lang'
import { useInstance } from '@/composables/useInstance'
import type { PulsarState } from './usePulsarState'
import { view, Secret, useRbac } from './useRbac'
const { t } = i18n.global

const secrets = ref<Secret[]>([])
let lastOrg: string | undefined = undefined
interface Secret {
  name: string
  creationTimestamp?: string
  deletionTimestamp?: string
  instanceName: string
  location?: string
  keys?: string[]
  data?: Record<string, unknown>
}

const removeSecret = (name: string) => {
  secrets.value.forEach((s, i) => {
    if (s.name === name) {
      secrets.value[i].deletionTimestamp = new Date().toISOString()
    }
  })
}

const addSecret = (secret: Secret) => {
  secrets.value = [secret, ...secrets.value]
}

const hasSecrets = computed(() => {
  return secrets.value.length > 0
})
const secretNameList = computed(() => {
  return secrets.value.map(secret => secret.name)
})

const getSecrets = async (organization?: string | undefined) => {
  if (!organization) {
    organization = usePulsarState().mustOrganization()
  }
  const { data } = await useCloudApi().listNamespacedSecret(organization)

  secrets.value = data.items.map(secret => {
    return {
      name: secret.metadata?.name ?? '',
      creationTimestamp: secret.metadata?.creationTimestamp,
      deletionTimestamp: secret.metadata?.deletionTimestamp,
      instanceName: secret.instanceName,
      location: secret.location,
      keys: Object.keys(secret.data || {}),
      data: secret.data || {}
    } as Secret
  })
}

const createSecret = async ({
  location,
  name,
  data
}: {
  location: string
  name: string
  data: { key: string; value: string }[]
}) => {
  try {
    const organization = usePulsarState().mustOrganization()
    const { activeInstance } = useInstance()
    const dataList: Record<string, string> = {}
    data.forEach((element: { key: string; value: string }) => {
      dataList[element.key] = element.value
    })

    if (!activeInstance.value.metadata?.name || !activeInstance.value.metadata?.uid) {
      throw Error(t('secrets.errorInvalidInstance'))
    }

    const body: V1alpha1Secret = {
      data: dataList,
      metadata: {
        name,
        namespace: organization,
        ownerReferences: [
          {
            apiVersion: 'cloud.streamnative.io/v1alpha1',
            uid: activeInstance.value.metadata?.uid,
            name: activeInstance.value.metadata?.name,
            kind: 'PulsarInstance'
          }
        ]
      },
      location: location,
      instanceName: activeInstance.value.metadata?.name
    }

    const createSecretResult = await useCloudApi().createNamespacedSecret(organization, body)

    if (!createSecretResult.data.metadata?.name) {
      throw Error('created secret is missing name')
    }

    addSecret({
      name: createSecretResult.data.metadata?.name,
      creationTimestamp: createSecretResult.data.metadata?.creationTimestamp,
      deletionTimestamp: undefined,
      instanceName: activeInstance.value.metadata?.name
    })
  } catch (e) {
    throw Error(getErrorMessage(e, 'createSecret Error'))
  }
}
const deleteSecret = async (secretName: string) => {
  const organization = usePulsarState().mustOrganization()
  await useCloudApi().deleteNamespacedSecret(secretName, organization)
  removeSecret(secretName)
}

const init = (
  initialState: PulsarState,
  can?: ((action: string, type: any, conditions?: any) => boolean) | true
) => {
  const { organization } = usePulsarState()
  const { abilityUpdating } = useRbac()

  const valueChanged = async ([org, ab]: [string | undefined, boolean | undefined]) => {
    if (!org) {
      secrets.value = []
      lastOrg = undefined
      return
    }
    if (ab) {
      return
    }

    if (org !== lastOrg || !ab) {
      if (can && (can === true || can(view, Secret))) {
        await getSecrets(org)
      }
    }
    lastOrg = org
  }

  watch([organization, abilityUpdating], valueChanged)
  return valueChanged([initialState.organization, abilityUpdating.value])
}

export const useSecret = () => {
  return {
    secrets,
    hasSecrets,
    secretNameList,
    deleteSecret,
    createSecret,
    getSecrets,
    init
  }
}
