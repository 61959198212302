const pricePackage2024StartDateMs = 1710201600000 // 2024/03/12 00:00 UTC

export interface PriceModel {
  compute: number
  storage: number
  function: number
  data_in: number
  data_out: number
  disk: number
}
export interface PricePackage {
  name: string
  hosted: PriceModel
  'hosted-pro'?: PriceModel
  managed: PriceModel
  'managed-pro'?: PriceModel
}
export type PricePackageKeys = '2023-pricing-package' | '2024-pricing-package'

export const pricePackage: Record<PricePackageKeys, PricePackage> = {
  '2023-pricing-package': {
    name: '2023-pricing-package',
    hosted: {
      compute: 0.1,
      storage: 0.16,
      function: 0.1,
      data_in: 0.13,
      data_out: 0.04,
      disk: 0.000123288
    },
    managed: {
      compute: 0.2,
      storage: 0.3,
      function: 0.2,
      data_in: 0,
      data_out: 0,
      disk: 0
    }
  },
  '2024-pricing-package': {
    name: '2024-pricing-package',
    hosted: {
      compute: 0.24,
      storage: 0.3,
      function: 0.24,
      data_in: 0.13,
      data_out: 0.04,
      disk: 0.000123288
    },
    managed: {
      compute: 0.2,
      storage: 0.3,
      function: 0.1,
      data_in: 0,
      data_out: 0,
      disk: 0
    },
    'hosted-pro': {
      compute: 0.48,
      storage: 0.6,
      function: 0.3,
      data_in: 0.25,
      data_out: 0.09,
      disk: 0.000547945
    },
    'managed-pro': {
      compute: 0.4,
      storage: 0.6,
      function: 0.2,
      data_in: 0,
      data_out: 0,
      disk: 0
    }
  }
}

export const getDefaultPricePackage = () => {
  return Date.now() < pricePackage2024StartDateMs
    ? pricePackage['2023-pricing-package']
    : pricePackage['2024-pricing-package']
}
