import { useNamespacesPulsarAdmin } from '@/composables/pulsarAdmin'
import type {
  RetentionPolicies,
  DispatchRateImpl,
  SubscribeRate,
  BacklogQuota
} from '@streamnative/pulsar-admin-client-typescript'

// TODO, this function hides error prone functionality with the default values. we should refactor this
// to be explicit.
const buildNamespacePulsarAdmin = (
  organization: string | undefined = usePulsarState().organization.value,
  clusterUid: string | undefined = usePulsarState().clusterUid.value
) => {
  if (!organization) {
    throw Error(`Organization is not set.`)
  }
  if (!clusterUid) {
    throw Error(`Cluster is not set.`)
  }
  return useNamespacesPulsarAdmin(organization, clusterUid)
}

export const fetchNamespaces = async ({
  organization,
  clusterUid,
  tenant
}: {
  organization?: string
  clusterUid?: string
  tenant: string
}) => {
  const namespacePulsarAdmin = buildNamespacePulsarAdmin(organization, clusterUid)
  const res = await namespacePulsarAdmin.getTenant(tenant, { cache: true })
  // this is funky??
  res.data = res.data.map(namespace => {
    return namespace.replace(`${tenant}/`, '')
  })
  return res
}

export const fetchNamespacePolicies = ({
  organization,
  clusterUid,
  tenant,
  namespace
}: {
  organization?: string
  clusterUid?: string
  tenant: string
  namespace: string
}) => {
  const namespacePulsarAdmin = buildNamespacePulsarAdmin(organization, clusterUid)
  return namespacePulsarAdmin.getPolicies(tenant, namespace)
}

export const createNamespace = ({
  organization,
  clusterUid,
  tenant,
  namespace,
  options
}: {
  organization?: string
  clusterUid?: string
  tenant: string
  namespace: string
  options?: { [key: string]: string | string[] }
}) => {
  const namespacePulsarAdmin = buildNamespacePulsarAdmin(organization, clusterUid)
  return namespacePulsarAdmin.createNamespace(tenant, namespace, options)
}

export const deleteNamespace = ({
  organization,
  clusterUid,
  tenant,
  namespace
}: {
  organization?: string
  clusterUid?: string
  tenant: string
  namespace: string
}) => {
  const namespacePulsarAdmin = buildNamespacePulsarAdmin(organization, clusterUid)
  return namespacePulsarAdmin.deleteNamespace(tenant, namespace)
}

export const getPermissions = ({
  organization,
  clusterUid,
  tenant,
  namespace
}: {
  organization?: string
  clusterUid?: string
  tenant: string
  namespace: string
}) => {
  const namespacePulsarAdmin = buildNamespacePulsarAdmin(organization, clusterUid)
  return namespacePulsarAdmin.getPermissions(tenant, namespace)
}

export const grantPermissions = ({
  organization,
  clusterUid,
  tenant,
  namespace,
  role,
  permissions
}: {
  organization?: string
  clusterUid?: string
  tenant: string
  namespace: string
  role: string
  permissions: string[]
}) => {
  const namespacePulsarAdmin = buildNamespacePulsarAdmin(organization, clusterUid)
  return namespacePulsarAdmin.grantPermissionOnNamespace(tenant, namespace, role, permissions)
}

export const revokePermissions = ({
  organization,
  clusterUid,
  tenant,
  namespace,
  role
}: {
  organization?: string
  clusterUid?: string
  tenant: string
  namespace: string
  role: string
}) => {
  const namespacePulsarAdmin = buildNamespacePulsarAdmin(organization, clusterUid)
  return namespacePulsarAdmin.revokePermissionsOnNamespace(tenant, namespace, role)
}

export const setBacklogQuota = ({
  organization,
  clusterUid,
  tenant,
  namespace,
  backlogQuotaType,
  backlogQuota
}: {
  organization?: string
  clusterUid?: string
  tenant: string
  namespace: string
  backlogQuotaType: 'destination_storage'
  backlogQuota: BacklogQuota
}) => {
  const namespacePulsarAdmin = buildNamespacePulsarAdmin(organization, clusterUid)
  return namespacePulsarAdmin.setBacklogQuota(tenant, namespace, backlogQuotaType, backlogQuota)
}

export const setMessageTtl = ({
  organization,
  clusterUid,
  tenant,
  namespace,
  messageTtl
}: {
  organization?: string
  clusterUid?: string
  tenant: string
  namespace: string
  messageTtl: number
}) => {
  const namespacePulsarAdmin = buildNamespacePulsarAdmin(organization, clusterUid)
  return namespacePulsarAdmin.setNamespaceMessageTTL(tenant, namespace, messageTtl)
}

// todo: why have this? this information is fetched in getPolicies res.retention_policies
export const getRetention = ({
  organization,
  clusterUid,
  tenant,
  namespace
}: {
  organization?: string
  clusterUid?: string
  tenant: string
  namespace: string
}) => {
  const namespacePulsarAdmin = buildNamespacePulsarAdmin(organization, clusterUid)
  return namespacePulsarAdmin.getRetention(tenant, namespace)
}

export const updateRetention = ({
  organization,
  clusterUid,
  tenant,
  namespace,
  retentionPolicies
}: {
  organization?: string
  clusterUid?: string
  tenant: string
  namespace: string
  retentionPolicies: RetentionPolicies
}) => {
  const namespacePulsarAdmin = buildNamespacePulsarAdmin(organization, clusterUid)
  // When a user unsets input values in the UI, the policy should be removed.
  // When set to blank, these values are actually strings.
  if (
    String(retentionPolicies.retentionTimeInMinutes) === '' &&
    String(retentionPolicies.retentionSizeInMB) === ''
  ) {
    return namespacePulsarAdmin.removeRetention(tenant, namespace, retentionPolicies)
  } else {
    return namespacePulsarAdmin.setRetention(tenant, namespace, retentionPolicies)
  }
}

export const unloadOnCluster = ({
  organization,
  clusterUid,
  tenant,
  namespace
}: {
  organization?: string
  clusterUid?: string
  tenant: string
  namespace: string
}) => {
  const namespacePulsarAdmin = buildNamespacePulsarAdmin(organization, clusterUid)
  return namespacePulsarAdmin.unloadNamespace(tenant, namespace)
}

export const unloadBundleOnCluster = ({
  organization,
  clusterUid,
  tenant,
  namespace,
  bundle
}: {
  organization?: string
  clusterUid?: string
  tenant: string
  namespace: string
  bundle: string
}) => {
  const namespacePulsarAdmin = buildNamespacePulsarAdmin(organization, clusterUid)
  return namespacePulsarAdmin.unloadNamespaceBundle(tenant, namespace, bundle)
}

export const setDispatchRate = ({
  organization,
  clusterUid,
  tenant,
  namespace,
  topicDispatchRate
}: {
  organization?: string
  clusterUid?: string
  tenant: string
  namespace: string
  topicDispatchRate: DispatchRateImpl
}) => {
  const namespacePulsarAdmin = buildNamespacePulsarAdmin(organization, clusterUid)
  return namespacePulsarAdmin.setDispatchRate(tenant, namespace, topicDispatchRate)
}

export const setSubscribeRate = ({
  organization,
  clusterUid,
  tenant,
  namespace,
  clusterSubscribeRate
}: {
  organization?: string
  clusterUid?: string
  tenant: string
  namespace: string
  clusterSubscribeRate: SubscribeRate
}) => {
  const namespacePulsarAdmin = buildNamespacePulsarAdmin(organization, clusterUid)
  return namespacePulsarAdmin.setSubscribeRate(tenant, namespace, clusterSubscribeRate)
}

export const setSubscriptionDispatchRate = ({
  organization,
  clusterUid,
  tenant,
  namespace,
  subscriptionDispatchRate
}: {
  organization?: string
  clusterUid?: string
  tenant: string
  namespace: string
  subscriptionDispatchRate: DispatchRateImpl
}) => {
  const namespacePulsarAdmin = buildNamespacePulsarAdmin(organization, clusterUid)
  return namespacePulsarAdmin.setSubscriptionDispatchRate(
    tenant,
    namespace,
    subscriptionDispatchRate
  )
}

export const clearBacklogOnCluster = ({
  organization,
  clusterUid,
  tenant,
  namespace
}: {
  organization?: string
  clusterUid?: string
  tenant: string
  namespace: string
}) => {
  const namespacePulsarAdmin = buildNamespacePulsarAdmin(organization, clusterUid)
  return namespacePulsarAdmin.clearNamespaceBacklog(tenant, namespace)
}
export const clearBundleBacklogOnCluster = ({
  organization,
  clusterUid,
  tenant,
  namespace,
  bundle
}: {
  organization?: string
  clusterUid?: string
  tenant: string
  namespace: string
  bundle: string
}) => {
  const namespacePulsarAdmin = buildNamespacePulsarAdmin(organization, clusterUid)
  return namespacePulsarAdmin.clearNamespaceBundleBacklog(tenant, namespace, bundle)
}

export const setNamespaceReplicationClusters = ({
  organization,
  clusterUid,
  tenant,
  namespace,
  clusters
}: {
  organization?: string
  clusterUid?: string
  tenant: string
  namespace: string
  clusters: string[]
}) => {
  const namespacePulsarAdmin = buildNamespacePulsarAdmin(organization, clusterUid)
  return namespacePulsarAdmin.setNamespaceReplicationClusters(tenant, namespace, clusters)
}

export const setSubscriptionAuthMode = ({
  organization,
  clusterUid,
  tenant,
  namespace,
  mode
}: {
  organization?: string
  clusterUid?: string
  tenant: string
  namespace: string
  mode: string
}) => {
  const namespacePulsarAdmin = buildNamespacePulsarAdmin(organization, clusterUid)
  return namespacePulsarAdmin.setSubscriptionAuthMode(tenant, namespace, mode)
}

export const setCompactionThreshold = ({
  organization,
  clusterUid,
  tenant,
  namespace,
  compactionThreshold
}: {
  organization?: string
  clusterUid?: string
  tenant: string
  namespace: string
  compactionThreshold: number
}) => {
  const namespacePulsarAdmin = buildNamespacePulsarAdmin(organization, clusterUid)
  return namespacePulsarAdmin.setCompactionThreshold(tenant, namespace, compactionThreshold)
}

export const setSchemaAutoUpdateStrategy = ({
  organization,
  clusterUid,
  tenant,
  namespace,
  strategy
}: {
  organization?: string
  clusterUid?: string
  tenant: string
  namespace: string
  strategy: string
}) => {
  const namespacePulsarAdmin = buildNamespacePulsarAdmin(organization, clusterUid)
  return namespacePulsarAdmin.setSchemaAutoUpdateCompatibilityStrategy(tenant, namespace, strategy)
}

export const setSchemaValidationEnforced = ({
  organization,
  clusterUid,
  tenant,
  namespace,
  enforced
}: {
  organization?: string
  clusterUid?: string
  tenant: string
  namespace: string
  enforced: boolean
}) => {
  const namespacePulsarAdmin = buildNamespacePulsarAdmin(organization, clusterUid)
  return namespacePulsarAdmin.setSchemaValidtionEnforced(tenant, namespace, enforced)
}

export const setMaxProducersPerTopic = ({
  organization,
  clusterUid,
  tenant,
  namespace,
  maxCount
}: {
  organization?: string
  clusterUid?: string
  tenant: string
  namespace: string
  maxCount: number
}) => {
  const namespacePulsarAdmin = buildNamespacePulsarAdmin(organization, clusterUid)
  return namespacePulsarAdmin.setMaxProducersPerTopic(tenant, namespace, maxCount)
}

export const setMaxConsumersPerTopic = ({
  organization,
  clusterUid,
  tenant,
  namespace,
  maxCount
}: {
  organization?: string
  clusterUid?: string
  tenant: string
  namespace: string
  maxCount: number
}) => {
  const namespacePulsarAdmin = buildNamespacePulsarAdmin(organization, clusterUid)
  return namespacePulsarAdmin.setMaxConsumersPerTopic(tenant, namespace, maxCount)
}

export const setMaxConsumersPerSubscription = ({
  organization,
  clusterUid,
  tenant,
  namespace,
  maxCount
}: {
  organization?: string
  clusterUid?: string
  tenant: string
  namespace: string
  maxCount: number
}) => {
  const namespacePulsarAdmin = buildNamespacePulsarAdmin(organization, clusterUid)
  return namespacePulsarAdmin.setMaxConsumersPerSubscription(tenant, namespace, maxCount)
}
