import * as cloud from '@streamnative/cloud-api-client-typescript'
import getAxiosInstance from '@/utils/axios'
import { auth } from '@/auth'

const axiosInstance = getAxiosInstance()
axiosInstance.interceptors.request.use(
  async config => {
    // set auth
    config.headers['Authorization'] = `Bearer ${await auth.getAccessTokenSilently()}`

    return config
  },
  e => {
    return Promise.reject(e)
  }
)

const cloudApiInstance = new cloud.CloudStreamnativeIoV1alpha1Api(
  undefined,
  '/cloud-api',
  axiosInstance
)

const authApiInstance = new cloud.AuthorizationStreamnativeIoV1alpha1Api(
  undefined,
  '/cloud-api',
  axiosInstance
)

const billingApiInstance = new cloud.BillingStreamnativeIoV1alpha1Api(
  undefined,
  '/cloud-api',
  axiosInstance
)

export const useCloudApi = (): cloud.CloudStreamnativeIoV1alpha1Api => {
  return cloudApiInstance
}

export const useAuthApi = (): cloud.AuthorizationStreamnativeIoV1alpha1Api => {
  return authApiInstance
}

export const useBillingApi = (): cloud.BillingStreamnativeIoV1alpha1Api => {
  return billingApiInstance
}
