import type { TranslateResult } from 'vue-i18n'
import type { QNotifyUpdateOptions } from 'quasar'
import { Notify, Loading, QSpinnerOval } from 'quasar'

export const useSuccess = (text: string): ((props?: QNotifyUpdateOptions) => void) => {
  return Notify.create({
    message: text,
    color: 'positive',
    attrs: {
      'data-ta-tag': 'use-success-notification'
    },
    timeout: 500, // defaults to 5000 which causes test failures
    group: true,
    ignoreDefaults: true,
    position: 'top'
  })
}
export const useError = (
  error: unknown | string | TranslateResult
): ((props?: QNotifyUpdateOptions) => void) => {
  return Notify.create({
    message: getErrorMessage(error),
    color: 'negative',
    attrs: {
      'data-ta-tag': 'use-error-notification'
    },
    position: 'top'
  })
}
export const useWarn = (
  text: string | TranslateResult
): ((props?: QNotifyUpdateOptions) => void) => {
  return Notify.create({
    message: text,
    color: 'warning',
    position: 'top'
  })
}
export const useLoading = (): { show: () => void; close: () => void } => {
  Loading.show({
    spinner: QSpinnerOval,
    spinnerSize: 48,
    backgroundColor: 'transparent',
    spinnerColor: 'grey-10'
  })

  return {
    show: () =>
      Loading.show({
        spinner: QSpinnerOval,
        spinnerSize: 48,
        backgroundColor: 'transparent',
        spinnerColor: 'grey-10'
      }),
    close: () => Loading.hide()
  }
}
