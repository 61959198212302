import { useCloudApi } from '@/composables/cloudApi'
import type { V1alpha1APIKeySpec } from '@streamnative/cloud-api-client-typescript'
import dayjs from 'dayjs'

export const listApiKeysApi = async (organization: string) => {
  try {
    const { data } = await useCloudApi().listNamespacedAPIKey(organization)
    return data
  } catch (e) {
    throw Error(getErrorMessage(e, 'fetch api keys Error'))
  }
}

export const apiKeysApi = async (apiKeyName: string, organization: string) => {
  try {
    const { data } = await useCloudApi().readNamespacedAPIKey(apiKeyName, organization)
    return data
  } catch (e) {
    throw Error(getErrorMessage(e, 'fetch api key Error'))
  }
}

export const createApiKeysApi = async (
  organization: string,
  {
    name,
    instanceName,
    serviceAccountName,
    description,
    expirationTime,
    encryptionKey
  }: {
    name: string
    instanceName: string
    serviceAccountName: string
    description: string
    expirationTime: string
    encryptionKey: {
      jwk?: string
      pem?: string
    }
  }
) => {
  try {
    const spec: V1alpha1APIKeySpec = {
      description,
      encryptionKey,
      expirationTime,
      instanceName,
      serviceAccountName
    }
    const { data } = await useCloudApi().createNamespacedAPIKey(organization, {
      metadata: {
        name
      },
      spec
    })
    return data
  } catch (e) {
    throw Error(getErrorMessage(e, 'create api keys Error'))
  }
}

export const editApiKeysApi = async (
  apiKeyName: string,
  organization: string,
  {
    description
  }: {
    description?: string
  }
) => {
  try {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const patchArray: Record<string, any> = [
      {
        op: 'replace',
        path: `/spec/description`,
        value: description
      }
    ]
    const { data } = await useCloudApi().patchNamespacedAPIKey(apiKeyName, organization, patchArray)
    return data
  } catch (e) {
    throw Error(getErrorMessage(e, 'edit api keys Error'))
  }
}

export const revokeApiKeysApi = async (apiKeyName: string, organization: string) => {
  try {
    const {
      data: { spec }
    } = await useCloudApi().readNamespacedAPIKey(apiKeyName, organization)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const patchArray: Record<string, any> = []
    if (spec?.revoke === undefined) {
      patchArray.push({
        op: 'add',
        path: `/spec/revoke`,
        value: true
      })
    } else {
      patchArray.push({
        op: 'replace',
        path: `/spec/revoke`,
        value: true
      })
    }
    const { data } = await useCloudApi().patchNamespacedAPIKey(apiKeyName, organization, patchArray)
    return data
  } catch (e) {
    throw Error(getErrorMessage(e, 'revoke api keys Error'))
  }
}

export const deleteApiKeysApi = async (apiKeyName: string, organization: string) => {
  try {
    await useCloudApi().deleteNamespacedAPIKey(apiKeyName, organization)
  } catch (e) {
    throw Error(getErrorMessage(e, 'delete api keys Error'))
  }
}
