import type {
  V1alpha1UserList,
  V1alpha1User,
  V1Status,
  V1alpha1RoleBinding
} from '@streamnative/cloud-api-client-typescript'
import { useCloudApi } from '@/composables/cloudApi'
import { getAdminsForTenants, updateTenantAdminWithSpecificInstance } from '@/api/tenants'

export const getUserInfoByUsername = async (
  organization: string,
  username: string
): Promise<V1alpha1User | { error: string }> => {
  try {
    const api = useCloudApi()
    const res = await api.readNamespacedUser(username, organization)
    return res.data
  } catch (e) {
    throw new Error(getErrorMessage(e, 'getUserInfoByUsername Error'))
  }
}

export const fetchUsers = async (
  organization: string
): Promise<V1alpha1UserList | { error: string }> => {
  try {
    const api = useCloudApi()
    const res = await api.listNamespacedUser(organization)
    return res.data
  } catch (e) {
    throw new Error(getErrorMessage(e, 'fetchUsers Error'))
  }
}

export const createUser = async (
  organization: string,
  user: V1alpha1User
): Promise<V1alpha1User | { error: string }> => {
  try {
    const api = useCloudApi()
    const res = await api.createNamespacedUser(organization, user)
    return res.data
  } catch (e) {
    throw new Error(getErrorMessage(e, 'createUser Error'))
  }
}

// export const updateUserInvite = async (username, namespace) => {
//   const api = await useCloudApi()
//   return api.readNamespacedUser(username, namespace)
// }

// export const resendInvite = async (username, namespace) => {
//   const api = await useCloudApi()
//   return api.readNamespacedUser(username, namespace)
// }

export const updateUser = async (
  organization: string,
  username: string,
  data: Array<Record<string, string>>
): Promise<V1alpha1User | { error: string }> => {
  try {
    const api = useCloudApi()
    const res = await api.patchNamespacedUser(username, organization, data)
    return res.data
  } catch (e) {
    throw new Error(getErrorMessage(e, 'updateUser Error'))
  }
}

export const deleteUser = async (
  organization: string,
  username: string
): Promise<V1Status | { error: string }> => {
  try {
    const api = useCloudApi()
    const res = await api.deleteNamespacedUser(username, organization)

    // remove the user from any instance it was administering
    const instanceToClusterToTenantAdmin = await getAdminsForTenants(organization)

    const promises = Object.keys(instanceToClusterToTenantAdmin).flatMap(async instance => {
      const clusters = instanceToClusterToTenantAdmin[instance].clusters
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const clusterPromises = clusters.map((cluster: any) => {
        const { tenant, adminRoles, uid, name } = cluster

        const indexOfAdmin = adminRoles.indexOf(username)

        // if admin is not found index is -1
        if (indexOfAdmin >= 0) {
          adminRoles.splice(indexOfAdmin, 1)
          const payload = {
            organization: organization,
            clusterUid: uid,
            instance: instance,
            tenant: tenant,
            data: { adminRoles, allowedClusters: [name] }
          }
          return updateTenantAdminWithSpecificInstance(payload)
        }

        return Promise.resolve()
      })

      return clusterPromises
    })

    await Promise.all(promises)

    return res.data
  } catch (e) {
    throw new Error(getErrorMessage(e, 'deleteUser Failed'))
  }
}

export const createRoleBinding = async (
  organization: string,
  data: V1alpha1RoleBinding
): Promise<V1alpha1RoleBinding | { error: string }> => {
  try {
    const api = useCloudApi()
    const res = await api.createNamespacedRoleBinding(organization, data)
    return res.data
  } catch (e) {
    throw new Error(getErrorMessage(e, 'createRoleBinding Failed'))
  }
}

export const getUserRoleBindings = async (
  organization: string,
  user: string
): Promise<Record<string, boolean>> => {
  if (user === '') {
    throw new Error(getErrorMessage('Username was blank', 'getUserRoleBindings Failed'))
  }

  try {
    const api = useCloudApi()
    const res = await api.listNamespacedRoleBinding(organization)
    const roles: Record<string, boolean> = {}
    for (const rb of res.data.items) {
      if (rb.metadata?.name?.includes(user)) {
        if (!rb.spec?.roleRef?.name) {
          continue
        }
        roles[rb.spec?.roleRef?.name] = true
      }
    }
    return roles
  } catch (e) {
    throw new Error(getErrorMessage(e, 'getUserRoleBindings Failed'))
  }
}

export const removeRoleBinding = async (
  organization: string,
  rolebinding: string
): Promise<void> => {
  try {
    const api = useCloudApi()
    await api.deleteNamespacedRoleBinding(rolebinding, organization)
  } catch (e) {
    throw new Error(getErrorMessage(e, 'removeUserRoleBinding Failed'))
  }
}
