import { useMemoize } from '@vueuse/core'
import type { RouteRecordName, RouteRecordRaw } from 'vue-router'
import { authRoutes } from '@/router/routes'
import { forEach } from 'lodash-es'
import { admin, type AppAbility } from '@/composables/useRbac'

export interface authQuery {
  AND?: {
    [name: string]: Array<string>
  }
  OR?: {
    [name: string]: Array<string>
  }
  NOT?: {
    [name: string]: Array<string>
  }
}

// Setup ability checks according to routing auth
const authRouteRules = useMemoize(() => {
  const rules: Record<string | number | symbol, authQuery> = {}
  const addRouteAuth = (r: RouteRecordRaw) => {
    if (r.meta?.requiresAuth && r.meta?.auth && r.name !== '' && r.name !== undefined) {
      rules[r.name] = r.meta.auth
    }

    if (r.children) {
      r.children.forEach(addRouteAuth)
    }
  }

  authRoutes.forEach(addRouteAuth)

  return rules
})

// Accepts a casl ability and returns a function that checks the ability to access a route based on the page name
export const routableByAbility =
  (ability: AppAbility) => (routeName: RouteRecordName | undefined | null) => {
    const canRoute = authRouteRules()
    // Can route if no rule is set up
    if (!routeName || !canRoute[routeName]) {
      return true
    }

    return permissibleByAbility(ability)(canRoute[routeName])
  }

export const permissibleByAbility = (ability: AppAbility) => (query: authQuery) => {
  let permitted = true

  forEach(query.AND, (permissions, resource) => {
    permitted &&= ability.can(admin, resource) || permissions.every(p => ability.can(p, resource))
  })

  forEach(query.OR, (permissions, resource) => {
    permitted ||= ability.can(admin, resource) || permissions.some(p => ability.can(p, resource))
  })

  forEach(query.NOT, (permissions, resource) => {
    permitted &&= !permissions.every(p => ability.can(p, resource))
  })

  return permitted
}
