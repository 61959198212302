import type { RouteMeta, RouteRecordRaw } from 'vue-router'

import { authGuard } from '@/auth'
import {
  adminTenant,
  create,
  CustomerPortalRequest,
  Product,
  PulsarCluster,
  PulsarInstance,
  Secret,
  ServiceAccount,
  Subscription,
  update,
  use,
  User,
  view,
  CloudEnvironment,
  CloudConnection
} from '@/composables/useRbac'

export const orgLevelPages = ['DashboardPage', 'InstancesPage']
export const clusterLevelPages = [
  'ClustersPage',
  'SecretsPage',
  'MetricsApiPage',
  'TenantsList',
  'ClientsPage',
  'KafkaClientsPage',
  'MqttClientsPage',
  'ClusterConfiguration'
]
export const tenantLevelPages = ['TenantDashboard', 'NamespacesPage', 'TenantConfiguration']
export const namespaceLevelPages = [
  'TopicsPage',
  'ConnectorsPage',
  'PulsarConnectorsPage',
  'KafkaConnectorsPage',
  'PulsarConnectorEditPage',
  'PulsarConnectorPage',
  'PulsarConnectorDashboard',
  'PulsarConnectorLogs',
  'PulsarConnectorExceptions',
  'KafkaConnectorEditPage',
  'KafkaConnectorPage',
  'KafkaConnectorLogs',
  'KafkaConnectorDashboard',
  'KafkaConnectorConfigurationPage',
  'KafkaConnectorExceptions',
  'PulsarConnectorCreatePage',
  'PulsarConnectorEditPage',
  'KafkaConnectorCreatePage',
  'KafkaConnectorEditPage',
  'FunctionDashboard',
  'FunctionOverview',
  'FunctionLogs',
  'FunctionExceptions',
  'FunctionsPage',
  'FunctionPage',
  'PfSqlListPage',
  'PfSqlPage',
  'PfSqlOverview',
  'PfSqlLogs',
  'PfSqlExceptions',
  'PfSqlDeployPage',
  'NamespacePolicies',
  'NamespaceOverview',
  'NamespaceDashboard'
]
export const topicLevelPages = [
  'TopicMetrics',
  'TopicOverview',
  'TopicSchema',
  'TopicMessages',
  'TopicStorage',
  'TopicPolicies',
  'TopicStats'
]
export const settingsLevelPages = ['ServiceAccountsPage', 'UsersPage', 'ServiceAccountPage']
const { functionEnabled } = useCluster()

const { isByocBaseOnCloudEnvironmentPossibleFunction } = useLD()
// TODO the permissions required for pfsql and functions is in flux, likely to change in near future
//      be sure to update here when they do
//TODO our meta: auth syntax needs a way to designate OR permissions eg [view, PulsarInstance] or [update, PulsarCluster] etc
// these routes are not authed
const openRoutes = <Array<RouteRecordRaw>>[
  // TODO remove me?
  {
    path: '/check-email',
    name: 'CheckEmailPage',
    component: () => import('@/views/login/CheckEmailPage.vue')
  },
  // TODO remove me?
  {
    path: '/email-verified',
    name: 'EmailVerifiedPage',
    component: () => import('@/views/login/EmailVerifiedPage.vue')
  },
  {
    path: '/callback/failed',
    name: 'CallbackFailedPage',
    component: () => import('@/views/login/CallbackFailedPage.vue')
  },
  {
    path: '/callback',
    name: 'CallbackPage',
    component: () => import('@/views/login/CallbackPage.vue')
  },
  // TODO remove me?
  {
    path: '/error',
    name: 'errorPage',
    component: () => import('@/views/error/ErrorPage.vue')
  },
  {
    path: '/404',
    name: 'NotFoundPage',
    component: () => import('@/views/error/NotFoundPage.vue')
  }
].map(r => {
  return {
    ...r,
    meta: { requiresAuth: false }
  }
})

const billingAuth: RouteMeta = {
  requiresAuth: true,
  auth: {
    // TODO maybe want customerportalrequest create here too
    // Min for organization-user
    AND: {
      [Product]: [view],
      [Subscription]: [view]
    }
  }
}

export const authRoutes = <Array<RouteRecordRaw>>[
  {
    path: '/signup',
    name: 'SignupFlowPage',
    component: () => import('@/views/login/SignupFlowPage.vue')
  },
  {
    path: '/suger/error',
    name: 'SugerErrorPage',
    component: () => import('@/views/login/SugerErrorPage.vue'),
    meta: billingAuth
  },
  {
    path: '/payment',
    name: 'PaymentPage',
    component: () => import('@/views/billing/PaymentPage.vue'),
    meta: billingAuth
  },
  {
    path: '/organizations',
    name: 'OrganizationsPage',
    meta: {
      requiresAuth: true
      // Organizations page is allowed for all users that are authenticated
      // it requires no extra permissions
    },
    component: () => import('@/views/organization/OrganizationsPage.vue')
  },
  {
    path: '/',
    component: () => import('@/layouts/MainLayout.vue'),
    children: [
      {
        path: '/',
        name: 'DashboardPage',
        component: () => import('@/views/dashboard/DashboardPage.vue'),
        meta: {
          requiresAuth: true
        }
      },
      // TODO child component need to have meta auth added, same with tenants, clients, etc
      {
        path: '/organization-usage',
        name: 'OrganizationUsagePage',
        meta: {
          requiresAuth: true,
          auth: {
            AND: { [CustomerPortalRequest]: [create] }
          }
        },
        component: () => import('@/views/organization/OrganizationUsagePage.vue')
      },
      {
        path: '/users',
        name: 'UsersPage',
        component: () => import('@/views/user/UsersPage.vue'),
        meta: {
          requiresAuth: true,
          auth: {
            AND: { [User]: [view] }
          }
        }
      },
      {
        path: '/instances',
        name: 'InstancesPage',
        component: () => import('@/views/instance/InstancesPage.vue'),
        meta: {
          requiresAuth: true,
          auth: {
            AND: { [PulsarInstance]: [use] }
          }
        }
      },
      {
        path: '/clients',
        name: 'ClientsPage',
        component: () => import('@/views/client/ClientsPage.vue'),
        meta: {
          requiresAuth: true,
          auth: {
            AND: { [PulsarCluster]: [use] }
          }
        }
      },
      {
        path: '/kafka-clients',
        name: 'KafkaClientsPage',
        component: () => import('@/views/client/KafkaClientsPage.vue'),
        meta: {
          requiresAuth: true,
          auth: {
            AND: { [PulsarCluster]: [use] }
          }
        }
      },
      {
        path: '/mqtt-clients',
        name: 'MqttClientsPage',
        component: () => import('@/views/client/MqttClientsPage.vue'),
        meta: {
          requiresAuth: true,
          auth: {
            AND: { [PulsarCluster]: [use] }
          }
        }
      },
      {
        path: '/metrics-api',
        name: 'MetricsApiPage',
        component: () => import('@/views/client/MetricsApiPage.vue'),
        meta: {
          requiresAuth: true,
          auth: {
            AND: { [PulsarCluster]: [use] }
          }
        }
      },
      {
        path: '/clusters',
        name: 'ClustersPage',
        component: () => import('@/views/cluster/ClustersPage.vue'),
        meta: {
          requiresAuth: true,
          auth: {
            AND: { [PulsarCluster]: [use] }
          }
        }
      },
      {
        path: '/cluster-configuration',
        name: 'ClusterConfiguration',
        component: () => import('@/views/cluster/ClusterConfiguration.vue'),
        meta: {
          requiresAuth: true,
          auth: {
            AND: { [PulsarCluster]: [use] }
          }
        }
      },
      // TODO routes get all kinds of screwy with the params here
      //      which leads to auther problems. not sure why this was
      //      setup this way
      {
        path: '/tenants',
        name: 'TenantsPage',
        component: () => import('@/views/tenant/TenantsPage.vue'),
        redirect: { name: 'TenantsList' },
        children: [
          {
            path: 'list',
            name: 'TenantsList',
            component: () => import('@/views/tenant/TenantsList.vue'),
            meta: {
              requiresAuth: true,
              auth: {
                AND: {
                  [PulsarCluster]: [adminTenant]
                },
                // if you are a read only you could admin tenants or your could use them,
                // either are valid
                OR: {
                  [PulsarCluster]: [use]
                }
              }
            }
          },
          {
            path: 'dashboard',
            name: 'TenantDashboard',
            component: () => import('@/views/tenant/TenantDashboard.vue'),
            meta: {
              requiresAuth: true,
              auth: {
                AND: {
                  [PulsarCluster]: [adminTenant]
                },
                // if you are a read only you could admin tenants or your could use them,
                // either are valid
                OR: {
                  [PulsarCluster]: [use]
                }
              }
            }
          },
          {
            path: 'configuration',
            name: 'TenantConfiguration',
            component: () => import('@/views/tenant/TenantConfiguration.vue'),
            meta: {
              requiresAuth: true,
              auth: {
                AND: {
                  [PulsarCluster]: [adminTenant]
                },
                // if you are a read only you could admin tenants or your could use them,
                // either are valid
                OR: {
                  [PulsarCluster]: [use]
                }
              }
            }
          },
          {
            path: 'namespaces',
            name: 'NamespacesPage',
            component: () => import('@/views/tenant/NamespaceList.vue'),
            meta: {
              requiresAuth: true,
              auth: {
                AND: {
                  [PulsarCluster]: [adminTenant]
                },
                // if you are a read only you could admin tenants or your could use them,
                // either are valid
                OR: {
                  [PulsarCluster]: [use]
                }
              }
            }
          },
          {
            path: ':tenant/namespaces/:namespace',
            name: 'NamespaceDashboard',
            component: () => import('@/views/tenant/namespace/dashboard/NamespaceDashboard.vue'),
            props: true,
            meta: {
              requiresAuth: true,
              auth: {
                AND: { [PulsarCluster]: [view] }
              }
            }
          },
          {
            path: ':tenant/namespaces/:namespace/overview',
            name: 'NamespaceOverview',
            props: true,
            component: () => import('@/views/tenant/namespace/overview/NamespaceOverview.vue'),
            meta: {
              requiresAuth: true,
              auth: {
                AND: { [PulsarCluster]: [view] }
              }
            }
          },
          {
            path: ':tenant/namespaces/:namespace/configuration',
            name: 'NamespacePolicies',
            props: true,
            component: () => import('@/views/tenant/namespace/policies/NamespacePolicies.vue'),
            meta: {
              requiresAuth: true,
              auth: {
                AND: { [PulsarCluster]: [view] }
              }
            }
          }
        ],
        meta: {
          requiresAuth: true,
          auth: {
            AND: { [PulsarInstance]: [view] }
          }
        }
      },
      {
        path: '/topics',
        component: () => import('@/views/topic/TopicsPage.vue'),
        name: 'TopicsPage',
        meta: {
          requiresAuth: true,
          auth: {
            AND: { [PulsarInstance]: [use] },
            OR: {
              [PulsarCluster]: [adminTenant]
            }
          }
        }
      },
      {
        path: '/topic',
        component: () => import('@/views/topic/TopicPage.vue'),
        name: 'TopicPage',
        redirect: { name: 'TopicMetrics' },
        children: [
          {
            path: '',
            name: 'TopicMetrics',
            component: () => import('@/views/topic/overview/TopicMetrics.vue'),
            meta: {
              requiresAuth: true,
              auth: {
                AND: { [PulsarInstance]: [use] },
                OR: {
                  [PulsarCluster]: [adminTenant]
                }
              }
            }
          },
          {
            path: '',
            name: 'TopicOverview',
            component: () => import('@/views/topic/overview/TopicOverview.vue'),
            meta: {
              requiresAuth: true,
              auth: {
                AND: { [PulsarInstance]: [use] },
                OR: {
                  [PulsarCluster]: [adminTenant]
                }
              }
            }
          },
          {
            path: 'schema',
            name: 'TopicSchema',
            component: () => import('@/views/topic/schema/TopicSchema.vue'),
            meta: {
              requiresAuth: true,
              auth: {
                AND: { [PulsarInstance]: [use] },
                OR: {
                  [PulsarCluster]: [adminTenant]
                }
              }
            }
          },
          {
            path: 'messages',
            name: 'TopicMessages',
            component: () => import('@/views/topic/messages/TopicMessages.vue'),
            meta: {
              requiresAuth: true,
              auth: {
                AND: { [PulsarInstance]: [use] },
                OR: {
                  [PulsarCluster]: [adminTenant]
                }
              }
            }
          },
          {
            path: 'policies',
            name: 'TopicPolicies',
            component: () => import('@/views/topic/policies/TopicPolicies.vue'),
            meta: {
              requiresAuth: true,
              auth: {
                AND: { [PulsarInstance]: [use] },
                OR: {
                  [PulsarCluster]: [adminTenant]
                }
              }
            }
          },
          {
            path: 'stats',
            name: 'TopicStats',
            component: () => import('@/views/topic/stats/TopicStats.vue'),
            meta: {
              requiresAuth: true,
              auth: {
                AND: { [PulsarInstance]: [use] },
                OR: {
                  [PulsarCluster]: [adminTenant]
                }
              }
            }
          }
        ],
        meta: {
          requiresAuth: true,
          auth: {
            AND: { [PulsarInstance]: [use] },
            OR: {
              [PulsarCluster]: [adminTenant]
            }
          }
        }
      },
      {
        path: '/service-accounts',
        component: () => import('@/views/service-account/ServiceAccountsPage.vue'),
        name: 'ServiceAccountsPage',
        meta: {
          requiresAuth: true,
          auth: {
            AND: { [ServiceAccount]: [view] }
          }
        }
      },
      {
        path: '/service-account/:id',
        name: 'ServiceAccountPage',
        component: () => import('@/views/service-account/ServiceAccountPage.vue'),
        meta: {
          requiresAuth: true,
          auth: {
            AND: { [ServiceAccount]: [view] }
          }
        }
      },
      {
        path: '/secrets',
        component: () => import('@/views/secrets/SecretsPage.vue'),
        name: 'SecretsPage',
        meta: {
          requiresAuth: true,
          auth: {
            AND: { [Secret]: [view] }
          }
        }
      },
      {
        path: '/connector',
        name: 'ConnectorsPage',
        component: () => import('@/views/connector/ConnectorWrapper.vue'),
        redirect: { name: 'PulsarConnectorsPage', params: { type: 'sink' } },
        children: [
          {
            path: 'pulsar/:type',
            name: 'PulsarConnectorsPage',
            props: true,
            component: () => import('@/views/connector/PulsarConnectorsPage.vue'),
            meta: {
              requiresAuth: true,
              auth: {
                AND: { [PulsarInstance]: [use] }
              },
              disableFn: () => {
                return !functionEnabled.value
              }
            }
          },
          {
            path: 'kafka/:type',
            name: 'KafkaConnectorsPage',
            props: true,
            component: () => import('@/views/connector/KafkaConnectorsPage.vue'),
            meta: {
              requiresAuth: true,
              auth: {
                AND: { [PulsarInstance]: [use] }
              },
              disableFn: () => {
                return !functionEnabled.value
              }
            }
          }
        ],
        meta: {
          requiresAuth: true,
          auth: {
            AND: { [PulsarInstance]: [use] }
          },
          disableFn: () => {
            return !functionEnabled.value
          }
        }
      },
      {
        path: '/connector/kafka/:type/:name',
        name: 'KafkaConnectorPage',
        component: () => import('@/views/connector/kafka/KafkaConnectorPage.vue'),
        meta: {
          requiresAuth: true,
          auth: {
            AND: { [PulsarInstance]: [use] }
          },
          disableFn: () => {
            return !functionEnabled.value
          }
        },
        props: true,
        redirect: { name: 'KafkaConnectorDashboard' },
        children: [
          {
            path: 'dashboard',
            name: 'KafkaConnectorDashboard',
            component: () => import('@/views/connector/kafka/KafkaConnectorDashboard.vue'),
            props: true,
            meta: {
              requiresAuth: true,
              auth: {
                AND: { [PulsarInstance]: [use] }
              },
              disableFn: () => {
                return !functionEnabled.value
              }
            }
          },
          {
            path: 'logs',
            name: 'KafkaConnectorLogs',
            component: () => import('@/views/connector/kafka/KafkaConnectorLogs.vue'),
            props: true,
            meta: {
              requiresAuth: true,
              auth: {
                AND: { [PulsarInstance]: [view] }
              },
              disableFn: () => {
                return !functionEnabled.value
              }
            }
          },
          {
            path: 'configuration',
            name: 'KafkaConnectorConfigurationPage',
            component: () => import('@/views/connector/kafka/KafkaConnectorConfigurationPage.vue'),
            props: true,
            meta: {
              requiresAuth: true,
              auth: {
                AND: { [PulsarInstance]: [view] }
              },
              disableFn: () => {
                return !functionEnabled.value
              }
            }
          }
        ]
      },
      {
        path: '/connector/pulsar/:type/:name',
        name: 'PulsarConnectorPage',
        component: () => import('@/views/connector/pulsar/PulsarConnectorPage.vue'),
        redirect: { name: 'PulsarConnectorDashboard' },
        props: true,
        children: [
          {
            path: 'dashboard',
            name: 'PulsarConnectorDashboard',
            component: () => import('@/views/connector/pulsar/PulsarConnectorDashboard.vue'),
            props: true,
            meta: {
              requiresAuth: true,
              auth: {
                AND: { [PulsarInstance]: [use] }
              },
              disableFn: () => {
                return !functionEnabled.value
              }
            }
          },
          {
            path: 'logs',
            name: 'PulsarConnectorLogs',
            component: () => import('@/views/connector/pulsar/PulsarConnectorLogs.vue'),
            props: true,
            meta: {
              requiresAuth: true,
              auth: {
                AND: { [PulsarInstance]: [use] }
              },
              disableFn: () => {
                return !functionEnabled.value
              }
            }
          },
          {
            path: 'exceptions',
            name: 'PulsarConnectorExceptions',
            component: () => import('@/views/connector/pulsar/PulsarConnectorExceptions.vue'),
            props: true,
            meta: {
              requiresAuth: true,
              auth: {
                AND: { [PulsarInstance]: [use] }
              },
              disableFn: () => {
                return !functionEnabled.value
              }
            }
          }
        ],
        meta: {
          requiresAuth: true,
          auth: {
            AND: { [PulsarInstance]: [view] }
          },
          disableFn: () => {
            return !functionEnabled.value
          }
        }
      },
      {
        path: '/connector/kafka/:type/create',
        name: 'KafkaConnectorCreatePage',
        props: true,
        component: () => import('@/views/connector/kafka/KafkaConnectorEditPage.vue'),
        meta: {
          requiresAuth: true,
          auth: {
            AND: { [PulsarInstance]: [use] }
          },
          disableFn: () => {
            return !functionEnabled.value
          }
        }
      },
      {
        path: '/connector/kafka/:type/:name/edit',
        name: 'KafkaConnectorEditPage',
        component: () => import('@/views/connector/kafka/KafkaConnectorEditPage.vue'),
        meta: {
          requiresAuth: true,
          auth: {
            AND: { [PulsarInstance]: [use] }
          },
          disableFn: () => {
            return !functionEnabled.value
          }
        },
        props: true
      },
      {
        path: '/connector/pulsar/:type/create',
        name: 'PulsarConnectorCreatePage',
        props: true,
        component: () => import('@/views/connector/pulsar/PulsarConnectorEditPage.vue'),
        meta: {
          requiresAuth: true,
          auth: {
            AND: { [PulsarInstance]: [use] }
          },
          disableFn: () => {
            return !functionEnabled.value
          }
        }
      },
      {
        path: '/connector/pulsar/:type/:name/edit',
        component: () => import('@/views/connector/pulsar/PulsarConnectorEditPage.vue'),
        name: 'PulsarConnectorEditPage',
        props: true,
        meta: {
          requiresAuth: true,
          auth: {
            AND: { [Secret]: [view], [PulsarInstance]: [update] }
          },
          disableFn: () => {
            return !functionEnabled.value
          }
        }
      },
      {
        path: '/functions',
        name: 'FunctionsPage',
        component: () => import('@/views/function/FunctionsPage.vue'),
        meta: {
          requiresAuth: true,
          auth: {
            AND: { [PulsarInstance]: [update] }
          },
          disableFn: () => {
            return !functionEnabled.value
          }
        }
      },
      {
        path: '/pfsqls',
        name: 'PfSqlListPage',
        component: () => import('@/views/pfsql/PfSqlListPage.vue'),
        meta: {
          requiresAuth: true,
          auth: {
            AND: { [PulsarInstance]: [use] }
          },
          disableFn: () => {
            return !functionEnabled.value
          }
        }
      },
      {
        path: '/pfsql/deploy',
        name: 'PfSqlDeployPage',
        component: () => import('@/views/pfsql/PfSqlDeployPage.vue'),
        meta: {
          requiresAuth: true,
          auth: {
            AND: { [PulsarInstance]: [view] }
          },
          disableFn: () => {
            return !functionEnabled.value
          }
        }
      },
      {
        path: '/pfsql',
        name: 'PfSqlPage',
        component: () => import('@/views/pfsql/PfSqlPage.vue'),
        redirect: { name: 'PfSqlOverview' },
        children: [
          {
            path: '',
            name: 'PfSqlOverview',
            component: () => import('@/views/pfsql/PfSqlOverview.vue'),
            meta: {
              requiresAuth: true,
              auth: {
                AND: { [PulsarInstance]: [update] }
              },
              disableFn: () => {
                return !functionEnabled.value
              }
            }
          },
          {
            path: 'logs',
            name: 'PfSqlLogs',
            component: () => import('@/views/pfsql/PfSqlLogs.vue'),
            meta: {
              requiresAuth: true,
              auth: {
                AND: { [PulsarInstance]: [update] }
              },
              disableFn: () => {
                return !functionEnabled.value
              }
            }
          },
          {
            path: 'exceptions',
            name: 'PfSqlExceptions',
            component: () => import('@/views/pfsql/PfSqlExceptions.vue'),
            meta: {
              requiresAuth: true,
              auth: {
                AND: { [PulsarInstance]: [update] }
              },
              disableFn: () => {
                return !functionEnabled.value
              }
            }
          }
        ],
        meta: {
          requiresAuth: true,
          auth: {
            AND: { [PulsarInstance]: [view] }
          },
          disableFn: () => {
            return !functionEnabled.value
          }
        }
      },
      {
        path: '/function',
        component: () => import('@/views/function/FunctionPage.vue'),
        name: 'FunctionPage',
        redirect: { name: 'FunctionDashboard' },
        children: [
          {
            path: '',
            name: 'FunctionDashboard',
            component: () => import('@/views/function/FunctionDashboard.vue'),
            meta: {
              requiresAuth: true,
              auth: {
                AND: { [PulsarInstance]: [update] }
              },
              disableFn: () => {
                return !functionEnabled.value
              }
            }
          },
          {
            path: '',
            name: 'FunctionOverview',
            component: () => import('@/views/function/FunctionOverview.vue'),
            meta: {
              requiresAuth: true,
              auth: {
                AND: { [PulsarInstance]: [update] }
              },
              disableFn: () => {
                return !functionEnabled.value
              }
            }
          },
          {
            path: 'logs',
            name: 'FunctionLogs',
            component: () => import('@/views/function/FunctionLogs.vue'),
            meta: {
              requiresAuth: true,
              auth: {
                AND: { [PulsarInstance]: [update] }
              },
              disableFn: () => {
                return !functionEnabled.value
              }
            }
          },
          {
            path: 'exceptions',
            name: 'FunctionExceptions',
            component: () => import('@/views/function/FunctionExceptions.vue'),
            meta: {
              requiresAuth: true,
              auth: {
                AND: { [PulsarInstance]: [update] }
              },
              disableFn: () => {
                return !functionEnabled.value
              }
            }
          }
        ],
        meta: {
          requiresAuth: true,
          auth: {
            AND: { [PulsarInstance]: [update] }
          },
          disableFn: () => {
            return !functionEnabled.value
          }
        }
      },
      {
        path: '/environment',
        name: 'CloudEnvironmentManagePage',
        redirect: { name: 'CloudEnvironmentsPage' },
        component: () => import('@/views/environment/CloudEnvironmentManagePage.vue'),
        children: [
          {
            path: 'environments',
            name: 'CloudEnvironmentsPage',
            component: () => import('@/views/environment/CloudEnvironmentsPage.vue'),
            meta: {
              requiresAuth: true,
              auth: {
                AND: {
                  [CloudEnvironment]: [use],
                  [CloudConnection]: [use]
                }
              },
              disableFn: () => {
                return !isByocBaseOnCloudEnvironmentPossibleFunction()
              }
            }
          },
          {
            path: 'connections',
            name: 'CloudConnectionsPage',
            component: () => import('@/views/environment/CloudConnectionsPage.vue'),
            meta: {
              requiresAuth: true,
              auth: {
                AND: {
                  [CloudEnvironment]: [use],
                  [CloudConnection]: [use]
                }
              }
            }
          }
        ],
        meta: {
          requiresAuth: true,
          auth: {
            AND: {
              [CloudEnvironment]: [use],
              [CloudConnection]: [use]
            }
          }
        }
      }
    ]
  },
  {
    path: '/deploying',
    name: 'DeployingPage',
    component: () => import('@/views/provision/DeployingPage.vue'),
    props: true,
    meta: {
      requiresAuth: true,
      auth: {
        AND: {
          [PulsarInstance]: [create, update],
          [PulsarCluster]: [create, update]
        }
      }
    }
  },
  {
    path: '/products',
    name: 'ProductsPage',
    component: () => import('@/views/provision/ProductsPage.vue'),
    meta: billingAuth
  },
  {
    path: '/provision',
    name: 'ProvisionPage',
    component: () => import('@/views/provision/ProvisionPage.vue'),
    meta: {
      requiresAuth: true,
      // Note, this also requires the update permission, but because we use
      // the same endpoint for updates and creation we cant check that here.
      // We have to check it in the actual components setup function.
      auth: {
        AND: {
          [PulsarInstance]: [create, update],
          [PulsarCluster]: [create, update]
        }
      }
    }
  },
  {
    path: '/environment-provision',
    name: 'CloudEnvironmentProvisionPage',
    component: () => import('@/views/environment/CloudEnvironmentProvisionPage.vue'),
    meta: {
      requiresAuth: true,
      // Note, this also requires the update permission, but because we use
      // the same endpoint for updates and creation we cant check that here.
      // We have to check it in the actual components setup function.
      auth: {
        AND: {
          [CloudEnvironment]: [create, update],
          [PulsarInstance]: [create, update],
          [PulsarCluster]: [create, update]
        }
      },
      disableFn: () => {
        return !isByocBaseOnCloudEnvironmentPossibleFunction()
      }
    }
  }
].map(r => {
  // TODO authGuard is also called in beforeEach, is this necessary?
  return {
    ...r,
    beforeEnter: authGuard
  }
})

// These will be conditionally added the to the router for the dev server
// and e2e tests.
export const e2eRoutes = <Array<RouteRecordRaw>>[
  {
    path: '/e2e/tenantadmin',
    name: 'PulsarAdminTest',
    component: () => import('@/views/test/PulsarAdmin.vue'),
    beforeEnter: authGuard,
    meta: {
      requiresAuth: true
    }
  }
]

// this needs to added last and redirect to 404
const notFoundRoutes = <Array<RouteRecordRaw>>[
  {
    path: '/:pathMatch(.*)*',
    redirect: '/404'
  }
]

// https://router.vuejs.org/guide/advanced/meta.html
declare module 'vue-router' {
  interface RouteMeta {
    requiresAuth?: boolean
    auth?: {
      AND?: {
        [name: string]: Array<string>
      }
      OR?: {
        [name: string]: Array<string>
      }
      NOT?: {
        [name: string]: Array<string>
      }
    }
    // some temporary sinario, for example: disabled some function from LD
    disableFn?: () => boolean
  }
}

export default openRoutes.concat(authRoutes).concat(notFoundRoutes)
